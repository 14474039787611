.selectize-input{
  border:1px solid #e4eaec;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.selectize-control{
  .item {
    background: @color-blue-grey-200 !important;
    padding: 2px 5px;
    border-radius: 3px;
  }
  &.category1{
    .item {
      background: @color-cyan-600 !important;
      color: #fff !important;
    }
  }

  &.category2{
    .item{
    background: @color-purple-600 !important;
    color: #fff !important;
    }
  }
}