// Width Helpers
// ========================================================================
.width-50 {
  width: 50px;
}
.width-100 {
  width: 100px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-250 {
  width: 250px;
}
.width-300 {
  width: 300px;
}
.width-350 {
  width: 350px;
}
.width-400 {
  width: 400px;
}
.width-450 {
  width: 450px;
}
.width-500 {
  width: 500px;
}
.width-full {
  width: 100% !important;
}



@media (max-width: @screen-xs-max) {
  .width-xs-50 {
    width: 50px;
  }
  .width-xs-100 {
    width: 100px;
  }
  .width-xs-150 {
    width: 150px;
  }
  .width-xs-200 {
    width: 200px;
  }
  .width-xs-250 {
    width: 250px;
  }
  .width-xs-300 {
    width: 300px;
  }
  .width-xs-350 {
    width: 350px;
  }
  .width-xs-400 {
    width: 400px;
  }
  .width-xs-450 {
    width: 450px;
  }
  .width-xs-500 {
    width: 500px;
  }
  .width-xs-100pc {
    width: 100%;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .width-sm-50 {
    width: 50px;
  }
  .width-sm-100 {
    width: 100px;
  }
  .width-sm-150 {
    width: 150px;
  }
  .width-sm-200 {
    width: 200px;
  }
  .width-sm-250 {
    width: 250px;
  }
  .width-sm-300 {
    width: 300px;
  }
  .width-sm-350 {
    width: 350px;
  }
  .width-sm-400 {
    width: 400px;
  }
  .width-sm-450 {
    width: 450px;
  }
  .width-sm-500 {
    width: 500px;
  }
  .width-sm-100pc {
    width: 100%;
  }
}


@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .width-md-50 {
    width: 50px;
  }
  .width-md-100 {
    width: 100px;
  }
  .width-md-150 {
    width: 150px;
  }
  .width-md-200 {
    width: 200px;
  }
  .width-md-250 {
    width: 250px;
  }
  .width-md-300 {
    width: 300px;
  }
  .width-md-350 {
    width: 350px;
  }
  .width-md-400 {
    width: 400px;
  }
  .width-md-450 {
    width: 450px;
  }
  .width-md-500 {
    width: 500px;
  }
}

@media (min-width: @screen-lg-min) {
  .width-lg-50 {
    width: 50px;
  }
  .width-lg-100 {
    width: 100px;
  }
  .width-lg-150 {
    width: 150px;
  }
  .width-lg-200 {
    width: 200px;
  }
  .width-lg-250 {
    width: 250px;
  }
  .width-lg-300 {
    width: 300px;
  }
  .width-lg-350 {
    width: 350px;
  }
  .width-lg-400 {
    width: 400px;
  }
  .width-lg-450 {
    width: 450px;
  }
  .width-lg-500 {
    width: 500px;
  }
}
