.ceditor {
  .mce-panel {
    background: #222733 !important;

    .mce-btn {
      background: #222733 !important;
      button {
        color: #fff !important;

        i {
          color: #fff !important;
          &.mce-caret {
            border-top-color: #fff !important;
          }
        }
      }
    }

  }

}