.page-login form {
  width: 340px;
  margin: 30px 0;
}
.page-login form a {
  margin-left: 20px;
}
@media (max-width: 767px) {
  .page-login form {
    width: auto;
  }
}
.site-navbar {
  background-color: #62a8ea;
}
.site-navbar .navbar-header {
  background-color: transparent;
  color: #ffffff;
}
.site-navbar .navbar-header .navbar-toggle {
  color: #ffffff;
}
.site-navbar .navbar-header .hamburger:before,
.site-navbar .navbar-header .hamburger:after,
.site-navbar .navbar-header .hamburger .hamburger-bar {
  background-color: #ffffff;
}
.site-navbar .navbar-header .navbar-brand {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
@media (min-width: 768px) {
  .site-navbar .navbar-header {
    width: 260px;
  }
}
.site-navbar .navbar-container {
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .site-navbar .navbar-container {
    margin-left: 260px;
  }
}
.site-navbar.navbar-inverse .navbar-container {
  background-color: transparent;
}
html,
body {
  height: 100%;
}
body {
  padding-top: 66px;
}
.site-menubar {
  z-index: 1400;
  position: fixed;
  top: 66px;
  height: 100%;
  height: -webkit-calc(100% - 66px);
  height: calc(100% - 66px);
  background: #263238;
  color: rgba(163, 175, 183, 0.9);
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.site-menubar-body {
  height: 100%;
  height: -webkit-calc(100% - 40px);
  height: calc(100% - 40px);
}
.site-menubar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.site-menubar a {
  outline: none;
}
.site-menubar a:hover,
.site-menubar a:focus {
  text-decoration: none;
}
.site-menubar.site-menubar-light {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.site-menubar.site-menubar-light .scrollable-inverse.scrollable .scrollable-bar-handle {
  background: rgba(163, 175, 183, 0.6);
}
.site-menubar-section {
  padding: 0 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.site-menubar-section > h4,
.site-menubar-section > h5 {
  color: #76838f;
}
.site-menubar-section .progress:last-child {
  margin-bottom: 0;
}
.site-menubar-footer {
  position: fixed;
  bottom: 0;
  display: block;
  z-index: 1400;
}
.site-menubar-footer > a {
  display: block;
  float: left;
  width: 33.33333333%;
  height: 40px;
  padding: 26px 0;
  color: #76838f;
  font-size: 16px;
  text-align: center;
  background-color: #21292e;
}
.site-menubar-footer > a:hover,
.site-menubar-footer > a:focus {
  background-color: #1e2427;
}
.site-menubar-light .site-menubar-footer > a {
  background-color: #e4eaec;
}
.site-menubar-light .site-menubar-footer > a:hover,
.site-menubar-light .site-menubar-footer > a:focus {
  background-color: #d5dee1;
}
.site-menu {
  font-size: 14px;
  overflow-y: hidden;
  padding-bottom: 20px;
}
.site-menu-item > a {
  white-space: nowrap;
  cursor: pointer;
}
.site-menu-icon {
  display: inline-block;
  width: 1em;
  text-align: center;
}
.site-menu-title {
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.site-menu-arrow {
  font-family: 'Web Icons';
  -webkit-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  transition: transform 0.15s;
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.site-menu-arrow:before {
  content: '\f17f';
}
.site-menu-arrow,
.site-menu-badge,
.site-menu-label {
  position: absolute;
  right: 30px;
  display: inline-block;
  vertical-align: middle;
}
.site-menu .badge,
.site-menu .label {
  padding: 2px 5px 3px;
  font-size: 12px;
  font-weight: 300;
}
.site-menu-item {
  position: relative;
  white-space: nowrap;
}
.site-menu-item a {
  display: block;
  color: rgba(163, 175, 183, 0.9);
}
.site-menu-item.is-hidden {
  opacity: 0;
}
.site-menu-item.is-shown {
  opacity: 1;
}
.site-menu > .site-menu-item {
  padding: 0;
}
.site-menu > .site-menu-item.open {
  background: #242f35;
}
.site-menu > .site-menu-item.open > a {
  color: #ffffff;
  background: transparent;
}
.site-menu > .site-menu-item.open.hover > a {
  background: transparent;
}
.site-menu > .site-menu-item.hover > a,
.site-menu > .site-menu-item:hover > a {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.02);
}
.site-menu > .site-menu-item.active {
  background: #242f35;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.site-menu > .site-menu-item.active > a {
  color: #ffffff;
  background: transparent;
}
.site-menu > .site-menu-item.active.hover > a {
  background: transparent;
}
.site-menu .site-menu-sub {
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  background: transparent;
}
.site-menu .site-menu-sub .site-menu-item {
  line-height: 36px;
  color: rgba(163, 175, 183, 0.9);
  background: transparent;
}
.site-menu .site-menu-sub .site-menu-item.has-sub {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.site-menu .site-menu-sub .site-menu-item > a {
  padding: 0 30px;
}
.site-menu .site-menu-sub .site-menu-item.open {
  background: rgba(0, 0, 0, 0.06);
}
.site-menu .site-menu-sub .site-menu-item.open > a {
  color: #ffffff;
}
.site-menu .site-menu-sub .site-menu-item.open.hover > a {
  background-color: transparent;
}
.site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menu .site-menu-sub .site-menu-item:hover > a {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.02);
}
.site-menu .site-menu-sub .site-menu-item.active {
  background: rgba(0, 0, 0, 0.06);
}
.site-menu .site-menu-sub .site-menu-item.active > a {
  color: #ffffff;
}
.site-menu .site-menu-sub .site-menu-item.active.hover > a {
  background-color: transparent;
}
.site-menubar-light .site-menu-item a {
  color: rgba(118, 131, 143, 0.9);
}
.site-menubar-light .site-menu-item.hover > a,
.site-menubar-light .site-menu-item:hover > a {
  background: transparent;
}
.site-menubar-light .site-menu > .site-menu-item.open {
  background: rgba(53, 131, 202, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.open > a {
  color: #62a8ea;
}
.site-menubar-light .site-menu > .site-menu-item.hover,
.site-menubar-light .site-menu > .site-menu-item:hover {
  background-color: rgba(53, 131, 202, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.hover > a,
.site-menubar-light .site-menu > .site-menu-item:hover > a {
  color: #62a8ea;
}
.site-menubar-light .site-menu > .site-menu-item.active {
  background: rgba(53, 131, 202, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.active > a {
  color: #62a8ea;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.open {
  background: rgba(53, 131, 202, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.open > a {
  color: #62a8ea;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover {
  background-color: rgba(53, 131, 202, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover > a {
  color: #62a8ea;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover.open,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover.open {
  background-color: rgba(53, 131, 202, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.active {
  background: rgba(53, 131, 202, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.active > a {
  color: #62a8ea;
}
.site-gridmenu {
  position: fixed;
  top: 66px;
  bottom: 0;
  padding: 20px 0;
  background-color: #263238;
  z-index: 1500;
  opacity: 0;
  visibility: hidden;
}
.js > .site-gridmenu {
  transition: opacity .5s ease 0s, visibility 0s;
}
.site-gridmenu-active > .site-gridmenu {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease 0s;
}
.site-gridmenu-active > .site-gridmenu ul {
  opacity: 1;
  transform: rotateX(0deg);
}
.site-gridmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: .4;
  transform: translateY(-25%) rotateX(35deg);
  transition: transform .5s ease 0s, opacity .5s ease 0s;
}
.site-gridmenu li {
  float: left;
  width: 50%;
  text-align: center;
}
.site-gridmenu li > a {
  display: block;
  padding: 30px 15px;
  color: #a3afb7;
}
.site-gridmenu li .icon {
  display: block;
  margin-bottom: 10px;
  font-size: 32px;
}
.site-gridmenu li:hover > a {
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.02);
}
body.site-gridmenu-active {
  overflow: hidden;
}
.site-gridmenu-toggle:after {
  opacity: 0;
  font-family: 'Web Icons';
  font-size: 14px;
  -webkit-transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  transition: opacity 0.15s;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  position: relative;
  right: -3px;
  top: -1px;
  content: '\f183';
}
.site-gridmenu-active .site-gridmenu-toggle:after {
  opacity: 1;
}
.site-sidebar {
  z-index: 1210;
}
.site-sidebar.slidePanel {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}
.site-sidebar.slidePanel.slidePanel-right,
.site-sidebar.slidePanel.slidePanel-left {
  width: 300px;
  overflow: hidden;
}
.site-sidebar-content {
  height: 100%;
}
.site-sidebar-nav.nav-tabs > li {
  display: table-cell;
  width: 1%;
}
.site-sidebar-nav.nav-tabs > li > a {
  font-size: 16px;
  line-height: 90px;
  padding: 0;
}
.site-sidebar-tab-content {
  height: 100%;
  height: -webkit-calc(100% - 90px);
  height: calc(100% - 90px);
}
.site-sidebar-tab-content > .tab-pane {
  height: 100%;
  padding: 20px 30px;
}
.site-sidebar-tab-content > .tab-pane.scrollable {
  padding: 0;
}
.site-sidebar-tab-content > .tab-pane .scrollable-content {
  padding: 20px 30px;
}
.site-sidebar-tab-content > .tab-pane .list-group {
  margin-left: -30px;
  margin-right: -30px;
}
.site-sidebar-tab-content > .tab-pane .list-group > .list-group-item {
  padding-left: 30px;
  padding-right: 30px;
}
.site-sidebar .conversation {
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 1700;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.site-sidebar .conversation.active {
  right: 0;
}
.site-sidebar .conversation-header {
  border-bottom: 1px solid #e4eaec;
}
.site-sidebar .conversation-header > * {
  margin: 0;
  padding: 0 30px;
  line-height: 90px;
}
.site-sidebar .conversation-more,
.site-sidebar .conversation-return {
  cursor: pointer;
  color: rgba(55, 71, 79, 0.4);
}
.site-sidebar .conversation-more:hover,
.site-sidebar .conversation-return:hover,
.site-sidebar .conversation-more:focus,
.site-sidebar .conversation-return:focus {
  color: rgba(55, 71, 79, 0.6);
}
.site-sidebar .conversation-more:active,
.site-sidebar .conversation-return:active {
  color: #37474f;
}
.site-sidebar .conversation-title {
  position: relative;
  top: 1px;
  color: #37474f;
  text-align: center;
  z-index: -1;
}
.site-sidebar .conversation-content {
  padding: 30px 15px;
}
.site-sidebar .conversation-reply {
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  border-top: 1px solid #e4eaec;
}
.site-sidebar .conversation-reply .form-control {
  border: none;
  border-right: 1px solid #e4eaec;
}
@media (max-width: 767px) {
  .site-sidebar.slidePanel.slidePanel-right,
  .site-sidebar.slidePanel.slidePanel-left {
    width: 100%;
  }
}
.site-action {
  position: fixed;
  z-index: 1200;
  bottom: 55px;
  right: 32px;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.site-action input {
  display: none;
}
.site-action .btn {
  box-shadow: 0 10px 10px 0 rgba(60, 60, 60, 0.1);
}
.site-action .front-icon {
  display: block;
}
.site-action .back-icon {
  display: none;
}
.site-action-buttons {
  display: none;
  position: absolute;
  bottom: 56px;
  left: 0;
  width: 100%;
  text-align: center;
}
.site-action-buttons .btn {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}
.site-action.active .front-icon {
  display: none;
}
.site-action.active .back-icon {
  display: block;
}
.site-action.active .site-action-buttons {
  display: block;
}
@media (max-width: 767px) {
  .site-action .btn-floating {
    width: 46px;
    height: 46px;
    padding: 0;
    font-size: 16px;
    box-shadow: 0 6px 6px 0 rgba(60, 60, 60, 0.1);
  }
  .site-action-buttons {
    bottom: 46px;
  }
}
.page {
  background: #f1f4f5;
  position: relative;
  min-height: calc(100% - 44px);
}
.page-dark.layout-full {
  color: #ffffff;
}
.page-dark.layout-full:before {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  z-index: -1;
}
.page-dark.layout-full:after {
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(38, 50, 56, 0.6);
  z-index: -1;
}
.page-dark.layout-full .brand {
  margin-bottom: 22px;
}
.page-dark.layout-full .brand-text {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}
.page-nav-tabs {
  padding: 0 30px;
}
.page-content {
  padding: 30px 30px;
}
.page-content-actions {
  padding: 0 30px 30px;
}
.page-content-actions .dropdown {
  display: inline-block;
}
.page-content-actions:before,
.page-content-actions:after {
  content: " ";
  display: table;
}
.page-content-actions:after {
  clear: both;
}
.page-content-table {
  padding: 0;
  max-width: 100%;
  overflow-x: auto;
}
.page-content-table .table > thead > tr > th,
.page-content-table .table > tbody > tr > th,
.page-content-table .table > thead > tr > td,
.page-content-table .table > tbody > tr > td {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-content-table .table > thead > tr > th:first-child,
.page-content-table .table > tbody > tr > th:first-child,
.page-content-table .table > thead > tr > td:first-child,
.page-content-table .table > tbody > tr > td:first-child {
  padding-left: 30px;
}
.page-content-table .table > thead > tr > th:last-child,
.page-content-table .table > tbody > tr > th:last-child,
.page-content-table .table > thead > tr > td:last-child,
.page-content-table .table > tbody > tr > td:last-child {
  padding-right: 30px;
}
.page-content-table .table > tbody > tr:hover > td {
  background-color: #f3f7f9;
}
.page-content-table .table > tbody > tr > td {
  cursor: pointer;
}
.page-content-table .table > tbody > tr:last-child td {
  border-bottom: 1px solid #e4eaec;
}
.page-content-table .table.is-indent > thead > tr > th.pre-cell,
.page-content-table .table.is-indent > tbody > tr > th.pre-cell,
.page-content-table .table.is-indent > thead > tr > td.pre-cell,
.page-content-table .table.is-indent > tbody > tr > td.pre-cell,
.page-content-table .table.is-indent > thead > tr > th.suf-cell,
.page-content-table .table.is-indent > tbody > tr > th.suf-cell,
.page-content-table .table.is-indent > thead > tr > td.suf-cell,
.page-content-table .table.is-indent > tbody > tr > td.suf-cell {
  padding: 0;
  border-top: none;
  border-bottom: none;
  width: 30px;
}
.page-content-table .table.is-indent > tbody > tr:first-child td {
  border-top: none;
}
.page-content-table .table.is-indent > tbody > tr:last-child td.pre-cell,
.page-content-table .table.is-indent > tbody > tr:last-child td.suf-cell {
  border-bottom: none;
}
.page-content-table .table.is-indent > tfoot > tr > td {
  border-top: none;
}
.page-content-table .pagination {
  margin-left: 30px;
  margin-right: 30px;
}
.page-copyright {
  margin-top: 60px;
  color: #37474f;
  font-size: 12px;
  letter-spacing: 1px;
}
.page-copyright .social a {
  margin: 0 10px;
  text-decoration: none;
}
.page-copyright .social .icon {
  font-size: 16px;
  color: rgba(55, 71, 79, 0.6);
}
.page-copyright .social .icon:hover,
.page-copyright .social .icon:focus {
  color: rgba(55, 71, 79, 0.8);
}
.page-copyright .social .icon.active,
.page-copyright .social .icon:active {
  color: #37474f;
}
.page-copyright-inverse {
  color: #ffffff;
}
.page-copyright-inverse .social .icon {
  color: #ffffff;
}
.page-copyright-inverse .social .icon:hover,
.page-copyright-inverse .social .icon:active {
  color: rgba(255, 255, 255, 0.8);
}
.page-header + .page-content {
  padding-top: 0;
}
.page-title {
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;
}
.page-title > .icon {
  margin-right: 0.3em;
}
.page-description {
  color: #a3afb7;
}
.page-header {
  padding: 30px 30px;
  margin-top: 0;
  margin-bottom: 0;
  background: transparent;
  position: relative;
  border-bottom: none;
}
.page-header-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}
.page-header-actions .btn-icon {
  margin-left: 6px;
}
.page-header-actions > * {
  margin-bottom: 0;
}
.page-header .breadcrumb {
  padding: 0;
  margin: 0;
}
.page-header-bordered {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border-bottom: 1px solid transparent;
}
.page-header-tabs {
  padding-bottom: 0;
}
.page-header-tabs .nav-tabs-line {
  margin-top: 5px;
  border-bottom-color: transparent;
}
.page-header-tabs .nav-tabs-line > li > a {
  padding: 5px 20px;
}
.page-aside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  border-right: 1px solid #e4eaec;
  background: #ffffff;
  -webkit-transition: top 0.5s ease, width 0.5s ease, opacity 0.3s ease;
  -o-transition: top 0.5s ease, width 0.5s ease, opacity 0.3s ease;
  transition: top 0.5s ease, width 0.5s ease, opacity 0.3s ease;
  overflow-y: auto;
}
.page-aside + .page-main {
  margin-left: 260px;
}
.page-aside-fixed {
  position: fixed;
}
.page-aside-section {
  position: relative;
}
.page-aside-section:first-child {
  padding-top: 22px;
}
.page-aside-section:last-child {
  margin-bottom: 22px;
}
.page-aside-section:after {
  content: '';
  position: relative;
  display: block;
  margin: 22px;
  border-bottom: 1px solid #e4eaec;
}
.page-aside-section:last-child:after {
  display: none;
}
.page-aside-switch {
  display: none;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 1px);
  top: calc(50% - 25px);
  background-color: white;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  border-radius: 0 100px 100px 0;
  line-height: 1;
  padding: 15px 8px 15px 4px;
}
.page-aside-title {
  padding: 10px 30px;
  margin: 20px 0 10px;
  font-weight: 500;
  color: #526069;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}
.page-aside .list-group {
  margin-bottom: 22px;
}
.page-aside .list-group-item {
  padding: 13px 30px;
  margin-bottom: 1px;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-aside .list-group-item .icon {
  color: #a3afb7;
}
.page-aside .list-group-item .item-right {
  float: right;
}
.page-aside .list-group-item:hover,
.page-aside .list-group-item:focus {
  background-color: #f3f7f9;
  border: none;
  color: #62a8ea;
}
.page-aside .list-group-item:hover > .icon,
.page-aside .list-group-item:focus > .icon {
  color: #62a8ea;
}
.page-aside .list-group-item.active {
  background-color: transparent;
  color: #62a8ea;
}
.page-aside .list-group-item.active > .icon {
  color: #62a8ea;
}
.page-aside .list-group-item.active:hover,
.page-aside .list-group-item.active:focus {
  background-color: #f3f7f9;
  border: none;
  color: #62a8ea;
}
.page-aside .list-group-item.active:hover > .icon,
.page-aside .list-group-item.active:focus > .icon {
  color: #62a8ea;
}
.page-aside .list-group.has-actions .list-group-item {
  cursor: pointer;
}
.page-aside .list-group.has-actions .list-group-item .list-editable {
  display: none;
  position: relative;
}
.page-aside .list-group.has-actions .list-group-item .list-editable .input-editable-close {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  outline: none;
  -webkit-appearance: none;
}
.page-aside .list-group.has-actions .list-group-item .list-editable .form-group {
  margin: 0;
}
.page-aside .list-group.has-actions .list-group-item .item-actions {
  display: none;
  position: absolute;
  right: 20px;
  top: 6px;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
  padding-left: 2px;
  padding-right: 2px;
  background-color: transparent;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
  color: #62a8ea;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .icon {
  margin: 0;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-right {
  display: none;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-actions {
  display: block;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
  color: #76838f;
}
@media (max-width: 1199px) {
  .page-aside {
    width: 220px;
  }
  .page-aside + .page-main {
    margin-left: 220px;
  }
}
@media (max-width: 767px) {
  .page-aside {
    position: fixed;
    top: 66px;
    bottom: 0;
    width: 0;
    border-color: transparent;
    z-index: 1100;
    overflow-y: visible;
  }
  .site-navbar-collapse-show .page-aside {
    top: 132px;
  }
  .site-menubar-changing .page-aside,
  .site-menubar-open .page-aside {
    opacity: 0;
  }
  .page-aside .page-aside-inner {
    height: 100%;
    overflow: auto;
    background-color: white;
    border-right: 1px solid #e4eaec;
    opacity: 0;
  }
  .page-aside.open {
    width: 260px;
  }
  .page-aside.open .page-aside-inner {
    opacity: 1;
  }
  .page-aside.open .page-aside-switch .wb-chevron-right {
    display: none;
  }
  .page-aside.open .page-aside-switch .wb-chevron-left {
    display: inline;
  }
  .page-aside + .page-main {
    margin-left: 0;
  }
  .page-aside-switch {
    display: block;
  }
  .site-menubar-open .page-aside-switch {
    display: none;
  }
  .page-aside-switch .wb-chevron-left {
    display: none;
  }
}
.site-footer {
  padding: 10px 30px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid #e4eaec;
  height: 44px;
}
.site-footer:before,
.site-footer:after {
  content: " ";
  display: table;
}
.site-footer:after {
  clear: both;
}
.site-footer-legal {
  float: left;
}
.site-footer-actions {
  float: right;
}
.site-footer-right {
  float: right;
}
.site-footer .scroll-to-top {
  color: #76838f;
}
.site-footer .scroll-to-top,
.site-footer .scroll-to-top:hover,
.site-footer .scroll-to-top:active {
  text-decoration: none;
}
@media (max-width: 480px) {
  .site-footer {
    height: auto;
  }
}
@media (max-width: 480px) {
  .site-footer-legal,
  .site-footer-right,
  .site-footer-actions {
    display: block;
    float: none;
    text-align: center;
  }
}
.layout-full {
  height: 100%;
}
body.layout-full {
  padding-top: 0;
}
.layout-full .page {
  height: 100%;
  margin: 0 !important;
  padding: 0;
  background-color: transparent;
}
.layout-full > .loader {
  margin-left: 0 !important;
}
@media (min-width: 1200px) {
  .layout-boxed {
    background: #e4eaec;
  }
  .layout-boxed,
  .layout-boxed .site-navbar {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}
.layout-boxed .slidePanel-right,
.layout-boxed .slidePanel-left {
  top: 0;
  z-index: 1510;
}
.site-print {
  padding-top: 0;
}
.site-print .site-navbar,
.site-print .site-menubar,
.site-print .site-gridmenu,
.site-print .site-footer {
  display: none;
}
.site-print .page {
  margin: 0 !important;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .css-menubar .page,
  .css-menubar .site-footer {
    margin-left: 90px;
  }
  .css-menubar .site-navbar .navbar-brand {
    float: none;
    text-align: center;
  }
  .css-menubar .site-navbar .navbar-brand-text {
    display: none;
  }
  .css-menubar .site-menu {
    overflow: visible;
  }
  .css-menubar .site-menu-category {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    height: 2px;
    background: #242f35;
    margin: 0;
  }
  .css-menubar .site-menu-category:first-child {
    visibility: hidden;
  }
  .css-menubar .site-menu > .site-menu-item {
    font-size: 14px;
  }
  .css-menubar .site-menu > .site-menu-item > a {
    line-height: 60px;
    height: 60px;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-title,
  .css-menubar .site-menu > .site-menu-item > a .site-menu-icon {
    -webkit-transition: visibility 0.25s, opacity 0.25s;
    -o-transition: visibility 0.25s, opacity 0.25s;
    transition: visibility 0.25s, opacity 0.25s;
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    text-align: center;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-arrow {
    -webkit-transition: visibility 0;
    -o-transition: visibility 0;
    transition: visibility 0;
    visibility: hidden;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-title {
    opacity: 0;
    visibility: hidden;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-icon {
    visibility: visible;
    opacity: 1;
    font-size: 24px;
    margin-right: 0;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-badge {
    left: 50px;
    top: -15px;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-label {
    display: none;
  }
  .css-menubar .site-menu > .site-menu-item.active {
    border-top: none;
    border-bottom: none;
  }
  .css-menubar .site-menu > .site-menu-item.hover > a .site-menu-icon,
  .css-menubar .site-menu > .site-menu-item.active > a .site-menu-icon,
  .css-menubar .site-menu > .site-menu-item.open > a .site-menu-icon {
    visibility: hidden;
    opacity: 0;
  }
  .css-menubar .site-menu > .site-menu-item.hover > a .site-menu-title,
  .css-menubar .site-menu > .site-menu-item.active > a .site-menu-title,
  .css-menubar .site-menu > .site-menu-item.open > a .site-menu-title {
    opacity: 1;
    visibility: visible;
  }
  .css-menubar .site-menu > .site-menu-item.hover > a .site-menu-badge,
  .css-menubar .site-menu > .site-menu-item.active > a .site-menu-badge,
  .css-menubar .site-menu > .site-menu-item.open > a .site-menu-badge {
    display: none;
  }
  .css-menubar .site-menu .site-menu-sub .site-menu-sub {
    display: none;
  }
  .css-menubar .site-menu .site-menu-sub .site-menu-item.open > a .site-menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .css-menubar .site-menu .site-menu-sub .site-menu-item.open > .site-menu-sub {
    display: block;
  }
  .css-menubar .site-menu .site-menu-sub .site-menu-item:not(.open) > a .site-menu-arrow {
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
  }
  .css-menubar .site-menu .site-menu-sub .site-menu-item:not(.open) > .site-menu-sub {
    display: none;
  }
  .css-menubar .site-menu > .site-menu-item > .site-menu-sub {
    -webkit-transition: visibility 0.25s, opacity 0.25s;
    -o-transition: visibility 0.25s, opacity 0.25s;
    transition: visibility 0.25s, opacity 0.25s;
    position: absolute;
    left: 90px;
    top: 0;
    width: 240px;
    background: #242f35;
    visibility: hidden;
    opacity: 0;
    overflow-y: auto;
  }
  .css-menubar .site-menu > .site-menu-item > .site-menu-sub-up {
    top: auto;
    bottom: 0;
  }
  .css-menubar .site-menu > .site-menu-item > .site-menu-sub .site-menu-item > a {
    padding: 0 30px;
  }
  .css-menubar .site-menu > .site-menu-item > .site-menu-sub .site-menu-icon {
    margin-right: 0.5em;
  }
  .css-menubar .site-menu > .site-menu-item.hover > .site-menu-sub {
    visibility: visible;
    opacity: 1;
  }
  .css-menubar .site-menubar-light .site-menu-category {
    background: rgba(53, 131, 202, 0.05);
  }
  .css-menubar .site-menubar-light .site-menu > .site-menu-item > .site-menu-sub {
    -webkit-box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.08);
    box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.08);
    background: #f3f8fb;
  }
  .css-menubar .site-menubar {
    width: 90px;
  }
  .css-menubar .site-menubar-section {
    padding: 30px;
    border-top: 2px solid #242f35;
    border-bottom: 2px solid #242f35;
    margin-top: 0;
  }
  .css-menubar .site-menubar-section > h4,
  .css-menubar .site-menubar-section > h5 {
    display: none;
  }
  .css-menubar .site-menubar-light .site-menubar-section {
    border-top: 2px solid rgba(53, 131, 202, 0.05);
    border-bottom: 2px solid rgba(53, 131, 202, 0.05);
  }
  .css-menubar .site-gridmenu {
    width: 90px;
  }
  .css-menubar .site-gridmenu li {
    float: none;
    width: 100%;
  }
  .css-menubar .site-gridmenu li > a {
    padding: 15px 0;
  }
  .css-menubar .site-gridmenu-toggle:after {
    display: none;
  }
  .css-menubar .site-menubar-footer {
    width: 90px;
  }
  .css-menubar .site-menubar-footer > a {
    display: none;
  }
  .css-menubar .site-menubar-footer > a.fold-show {
    width: 100%;
    float: none;
    display: block;
  }
  .css-menubar .site-menu > .site-menu-item:hover > a .site-menu-icon {
    visibility: hidden;
    opacity: 0;
  }
  .css-menubar .site-menu > .site-menu-item:hover > a .site-menu-title {
    opacity: 1;
    visibility: visible;
  }
  .css-menubar .site-menu > .site-menu-item:hover > a .site-menu-badge {
    display: none;
  }
  .css-menubar .site-menu-item:hover > .site-menu-sub {
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) and (min-width: 768px) {
  .css-menubar .site-navbar .navbar-header {
    width: 90px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) and (min-width: 768px) {
  .css-menubar .site-navbar .navbar-container {
    margin-left: 90px;
  }
}
.site-menubar-fold .page,
.site-menubar-fold .site-footer {
  margin-left: 90px;
}
@media (min-width: 768px) {
  .site-menubar-fold .site-navbar .navbar-header {
    width: 90px;
  }
}
@media (min-width: 768px) {
  .site-menubar-fold .site-navbar .navbar-container {
    margin-left: 90px;
  }
}
.site-menubar-fold .site-navbar .navbar-brand {
  float: none;
  text-align: center;
}
.site-menubar-fold .site-navbar .navbar-brand-text {
  display: none;
}
.site-menubar-fold .site-menu {
  overflow: visible;
}
.site-menubar-fold .site-menu-category {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  height: 2px;
  background: #242f35;
  margin: 0;
}
.site-menubar-fold .site-menu-category:first-child {
  visibility: hidden;
}
.site-menubar-fold .site-menu > .site-menu-item {
  font-size: 14px;
}
.site-menubar-fold .site-menu > .site-menu-item > a {
  line-height: 60px;
  height: 60px;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-title,
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-icon {
  -webkit-transition: visibility 0.25s, opacity 0.25s;
  -o-transition: visibility 0.25s, opacity 0.25s;
  transition: visibility 0.25s, opacity 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  text-align: center;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-arrow {
  -webkit-transition: visibility 0;
  -o-transition: visibility 0;
  transition: visibility 0;
  visibility: hidden;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-title {
  opacity: 0;
  visibility: hidden;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-icon {
  visibility: visible;
  opacity: 1;
  font-size: 24px;
  margin-right: 0;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-badge {
  left: 50px;
  top: -15px;
}
.site-menubar-fold .site-menu > .site-menu-item > a .site-menu-label {
  display: none;
}
.site-menubar-fold .site-menu > .site-menu-item.active {
  border-top: none;
  border-bottom: none;
}
.site-menubar-fold .site-menu > .site-menu-item.hover > a .site-menu-icon,
.site-menubar-fold .site-menu > .site-menu-item.active > a .site-menu-icon,
.site-menubar-fold .site-menu > .site-menu-item.open > a .site-menu-icon {
  visibility: hidden;
  opacity: 0;
}
.site-menubar-fold .site-menu > .site-menu-item.hover > a .site-menu-title,
.site-menubar-fold .site-menu > .site-menu-item.active > a .site-menu-title,
.site-menubar-fold .site-menu > .site-menu-item.open > a .site-menu-title {
  opacity: 1;
  visibility: visible;
}
.site-menubar-fold .site-menu > .site-menu-item.hover > a .site-menu-badge,
.site-menubar-fold .site-menu > .site-menu-item.active > a .site-menu-badge,
.site-menubar-fold .site-menu > .site-menu-item.open > a .site-menu-badge {
  display: none;
}
.site-menubar-fold .site-menu .site-menu-sub .site-menu-sub {
  display: none;
}
.site-menubar-fold .site-menu .site-menu-sub .site-menu-item.open > a .site-menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.site-menubar-fold .site-menu .site-menu-sub .site-menu-item.open > .site-menu-sub {
  display: block;
}
.site-menubar-fold .site-menu .site-menu-sub .site-menu-item:not(.open) > a .site-menu-arrow {
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
}
.site-menubar-fold .site-menu .site-menu-sub .site-menu-item:not(.open) > .site-menu-sub {
  display: none;
}
.site-menubar-fold .site-menu > .site-menu-item > .site-menu-sub {
  -webkit-transition: visibility 0.25s, opacity 0.25s;
  -o-transition: visibility 0.25s, opacity 0.25s;
  transition: visibility 0.25s, opacity 0.25s;
  position: absolute;
  left: 90px;
  top: 0;
  width: 240px;
  background: #242f35;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
}
.site-menubar-fold .site-menu > .site-menu-item > .site-menu-sub-up {
  top: auto;
  bottom: 0;
}
.site-menubar-fold .site-menu > .site-menu-item > .site-menu-sub .site-menu-item > a {
  padding: 0 30px;
}
.site-menubar-fold .site-menu > .site-menu-item > .site-menu-sub .site-menu-icon {
  margin-right: 0.5em;
}
.site-menubar-fold .site-menu > .site-menu-item.hover > .site-menu-sub {
  visibility: visible;
  opacity: 1;
}
.site-menubar-fold .site-menubar-light .site-menu-category {
  background: rgba(53, 131, 202, 0.05);
}
.site-menubar-fold .site-menubar-light .site-menu > .site-menu-item > .site-menu-sub {
  -webkit-box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 2px 1px 2px rgba(0, 0, 0, 0.08);
  background: #f3f8fb;
}
.site-menubar-fold .site-menubar {
  width: 90px;
}
.site-menubar-fold .site-menubar-section {
  padding: 30px;
  border-top: 2px solid #242f35;
  border-bottom: 2px solid #242f35;
  margin-top: 0;
}
.site-menubar-fold .site-menubar-section > h4,
.site-menubar-fold .site-menubar-section > h5 {
  display: none;
}
.site-menubar-fold .site-menubar-light .site-menubar-section {
  border-top: 2px solid rgba(53, 131, 202, 0.05);
  border-bottom: 2px solid rgba(53, 131, 202, 0.05);
}
.site-menubar-fold .site-gridmenu {
  width: 90px;
}
.site-menubar-fold .site-gridmenu li {
  float: none;
  width: 100%;
}
.site-menubar-fold .site-gridmenu li > a {
  padding: 15px 0;
}
.site-menubar-fold .site-gridmenu-toggle:after {
  display: none;
}
.site-menubar-fold .site-menubar-footer {
  width: 90px;
}
.site-menubar-fold .site-menubar-footer > a {
  display: none;
}
.site-menubar-fold .site-menubar-footer > a.fold-show {
  width: 100%;
  float: none;
  display: block;
}
.site-menubar-fold.site-menubar-changing .site-menu > .site-menu-item > .site-menu-sub {
  display: none;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover > a,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item:hover > a {
  background-color: transparent;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover {
  background: #37474f;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active > a .site-menu-icon,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open > a .site-menu-icon,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover > a .site-menu-icon {
  opacity: 1;
  visibility: visible;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active > a .site-menu-badge,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open > a .site-menu-badge,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover > a .site-menu-badge {
  display: inline-block;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active > a .site-menu-title,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open > a .site-menu-title {
  visibility: hidden;
  opacity: 0;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover > a .site-menu-title {
  opacity: 1;
  visibility: visible;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item > a .site-menu-title {
  -webkit-transition: visibility 0, opacity 0;
  -o-transition: visibility 0, opacity 0;
  transition: visibility 0, opacity 0;
  left: 90px;
  width: 240px;
  padding: 0 30px;
  background: #37474f;
  font-size: 16px;
  text-align: left;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item > .site-menu-sub {
  top: 60px;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item > .site-menu-sub-up {
  top: auto;
  bottom: 60px;
}
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.hover {
  background: #e8f1f8;
}
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item > a .site-menu-title {
  background: #e8f1f8;
}
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item > .site-menu-sub {
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media screen and (max-width: 767px), screen and (min-width: 1200px) {
  .css-menubar .site-menu-category {
    -webkit-transition: all .25s, font .1s .15s, color .1s .15s;
    -o-transition: all .25s, font .1s .15s, color .1s .15s;
    transition: all .25s, font .1s .15s, color .1s .15s;
    font-size: 15px;
    padding: 0 24px;
    color: #76838f;
    line-height: 46px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .css-menubar .site-menu > .site-menu-item > a .site-menu-title {
    font-size: 16px;
  }
  .css-menubar .site-menu-icon {
    margin-right: 16px;
  }
  .css-menubar .site-menu-title {
    display: inline-block;
    max-width: 160px;
  }
  .css-menubar .site-menu-title:first-child {
    margin-left: 32px;
  }
  .css-menubar .site-menu .site-menu-sub {
    display: none;
  }
  .css-menubar .site-menu-item {
    -webkit-transition: transform .1s, all .25s, border 0;
    -o-transition: transform .1s, all .25s, border 0;
    transition: transform .1s, all .25s, border 0;
    line-height: 36px;
  }
  .css-menubar .site-menu-item.open > a .site-menu-arrow {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .css-menubar .site-menu-item.open > .site-menu-sub {
    display: block;
  }
  .css-menubar .site-menu-item:not(.open) > a .site-menu-arrow {
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
  }
  .css-menubar .site-menu-item:not(.open) > .site-menu-sub {
    display: none;
  }
  .css-menubar .site-menu > .site-menu-item > a {
    padding: 0 30px;
    line-height: 46px;
  }
  .css-menubar.site-menubar-native .site-menubar-body {
    overflow-y: scroll;
  }
  .css-menubar .site-menubar {
    width: 260px;
  }
  .css-menubar .site-gridmenu {
    width: 260px;
  }
  .css-menubar .site-menubar-footer {
    width: 260px;
  }
  .css-menubar [data-toggle="menubar"] .hamburger-arrow-left {
    transform: rotate(180deg);
  }
  .css-menubar [data-toggle="menubar"] .hamburger-arrow-left:before {
    width: .6em;
    transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
  }
  .css-menubar [data-toggle="menubar"] .hamburger-arrow-left .hamburger-bar {
    border-radius: 0.2em;
  }
  .css-menubar [data-toggle="menubar"] .hamburger-arrow-left:after {
    width: .6em;
    transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
  }
  .css-menubar .site-menu-item:hover > .site-menu-sub {
    display: block;
  }
}
@media screen and (max-width: 767px) and (min-width: 768px), screen and (min-width: 1200px) and (min-width: 768px) {
  .css-menubar .site-navbar .navbar-header {
    width: 260px;
  }
}
@media screen and (max-width: 767px) and (min-width: 768px), screen and (min-width: 1200px) and (min-width: 768px) {
  .css-menubar .site-navbar .navbar-container {
    margin-left: 260px;
  }
}
@media screen and (min-width: 1200px) {
  .css-menubar .page,
  .css-menubar .site-footer {
    margin-left: 260px;
  }
}
.site-menubar-unfold .page,
.site-menubar-unfold .site-footer {
  margin-left: 260px;
}
@media (min-width: 768px) {
  .site-menubar-unfold .site-navbar .navbar-header {
    width: 260px;
  }
}
@media (min-width: 768px) {
  .site-menubar-unfold .site-navbar .navbar-container {
    margin-left: 260px;
  }
}
.site-menubar-unfold .site-menu-category {
  -webkit-transition: all .25s, font .1s .15s, color .1s .15s;
  -o-transition: all .25s, font .1s .15s, color .1s .15s;
  transition: all .25s, font .1s .15s, color .1s .15s;
  font-size: 15px;
  padding: 0 24px;
  color: #76838f;
  line-height: 46px;
  text-transform: uppercase;
  margin-top: 20px;
}
.site-menubar-unfold .site-menu > .site-menu-item > a .site-menu-title {
  font-size: 16px;
}
.site-menubar-unfold .site-menu-icon {
  margin-right: 16px;
}
.site-menubar-unfold .site-menu-title {
  display: inline-block;
  max-width: 160px;
}
.site-menubar-unfold .site-menu-title:first-child {
  margin-left: 32px;
}
.site-menubar-unfold .site-menu .site-menu-sub {
  display: none;
}
.site-menubar-unfold .site-menu-item {
  -webkit-transition: transform .1s, all .25s, border 0;
  -o-transition: transform .1s, all .25s, border 0;
  transition: transform .1s, all .25s, border 0;
  line-height: 36px;
}
.site-menubar-unfold .site-menu-item.open > a .site-menu-arrow {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.site-menubar-unfold .site-menu-item.open > .site-menu-sub {
  display: block;
}
.site-menubar-unfold .site-menu-item:not(.open) > a .site-menu-arrow {
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
}
.site-menubar-unfold .site-menu-item:not(.open) > .site-menu-sub {
  display: none;
}
.site-menubar-unfold .site-menu > .site-menu-item > a {
  padding: 0 30px;
  line-height: 46px;
}
.site-menubar-unfold.site-menubar-native .site-menubar-body {
  overflow-y: scroll;
}
.site-menubar-unfold .site-menubar {
  width: 260px;
}
.site-menubar-unfold .site-gridmenu {
  width: 260px;
}
.site-menubar-unfold .site-menubar-footer {
  width: 260px;
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left {
  transform: rotate(180deg);
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left:before {
  width: .6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left .hamburger-bar {
  border-radius: 0.2em;
}
.site-menubar-unfold [data-toggle="menubar"] .hamburger-arrow-left:after {
  width: .6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}
@media (max-width: 767px) {
  .site-menubar-unfold .page,
  .site-menubar-unfold .site-footer {
    margin-left: auto;
  }
  .site-menubar-open .page,
  .site-menubar-open .site-footer {
    -ms-transform: translate(260px, 0);
    -ms-transform: translate3d(260px, 0, 0);
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
  .site-menubar-changing {
    overflow: hidden;
  }
  .site-menubar-changing .page,
  .site-menubar-changing .site-footer {
    -webkit-transition: transform .25s;
    -o-transition: transform .25s;
    transition: transform .25s;
  }
  .site-gridmenu {
    width: 100% !important;
    background: rgba(38, 50, 56, 0.9);
  }
  .site-menubar-hide .site-menubar,
  .site-menubar-open .site-menubar {
    -webkit-transition: transform .25s, top .35s, height .35s;
    -o-transition: transform .25s, top .35s, height .35s;
    transition: transform .25s, top .35s, height .35s;
  }
  .site-menubar-open .site-menubar {
    -ms-transform: translate(0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media (max-width: 767px) {
  .site-navbar-collapsing {
    -webkit-transition: padding-top .35s;
    -o-transition: padding-top .35s;
    transition: padding-top .35s;
  }
  body.site-navbar-collapse-show {
    padding-top: 132px;
  }
  .site-navbar-collapse-show .slidePanel.slidePanel-right,
  .site-navbar-collapse-show .slidePanel.slidePanel-left {
    top: 135px;
    -webkit-transition: top .35s;
    -o-transition: top .35s;
    transition: top .35s;
  }
}
@media (max-width: 767px) {
  .site-navbar .brand {
    display: none;
  }
  .site-navbar .brand-mobile {
    display: block;
  }
  .site-menubar {
    top: 66px;
    height: -webkit-calc(100% - 66px);
    height: calc(100% - 66px);
    backface-visibility: hidden;
    perspective: 1000;
    -ms-transform: translate(-260px, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
  .site-navbar-collapse-show .site-menubar {
    top: 132px;
    height: -webkit-calc(100% - 132px);
    height: calc(100% - 132px);
  }
  .site-menubar-footer {
    visibility: hidden;
  }
  .site-menubar-open .site-menubar-footer {
    visibility: visible;
  }
  .disable-scrolling {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open {
    overflow: hidden;
    height: 100%;
  }
  body.site-menubar-open .page {
    overflow: hidden;
    height: 100%;
    pointer-events: none;
  }
}
@media (min-width: 768px) {
  .site-menubar-flipped .navbar-header {
    float: right;
  }
}
.site-menubar-flipped .site-menubar,
.site-menubar-flipped .site-menubar-footer {
  right: 0;
  left: auto;
}
.site-menubar-flipped .site-gridmenu {
  right: 0;
  left: auto;
}
.site-menubar-flipped .hamburger-arrow-left.collapsed:before,
.site-menubar-flipped .hamburger-arrow-left.unfolded:before {
  transform: translateX(-0.1em) translateY(0.1em) rotate(135deg);
}
.site-menubar-flipped .hamburger-arrow-left.collapsed:after,
.site-menubar-flipped .hamburger-arrow-left.unfolded:after {
  transform: translateX(-0.1em) translateY(-0.1em) rotate(45deg);
}
@media screen and (max-width: 767px) and (min-width: 768px), screen and (min-width: 1200px) and (min-width: 768px) {
  .css-menubar .site-menubar-flipped .site-navbar .navbar-container {
    margin-right: 260px;
    margin-left: 0;
  }
}
@media screen and (min-width: 1200px) {
  .css-menubar .site-menubar-flipped .page,
  .css-menubar .site-menubar-flipped .site-footer {
    margin-right: 260px;
    margin-left: 0;
  }
}
.site-menubar-unfold.site-menubar-flipped .page,
.site-menubar-unfold.site-menubar-flipped .site-footer {
  margin-right: 260px;
  margin-left: 0;
}
@media (min-width: 768px) {
  .site-menubar-unfold.site-menubar-flipped .site-navbar .navbar-container {
    margin-right: 260px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .site-menubar-flipped.site-menubar-unfold .page,
  .site-menubar-flipped.site-menubar-unfold .site-footer {
    margin-left: 0;
    margin-right: auto;
  }
  .site-menubar-flipped.site-menubar-open .page,
  .site-menubar-flipped.site-menubar-open .site-footer {
    -ms-transform: translate(-260px, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    -webkit-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
  .site-menubar-flipped.site-menubar-open .site-menubar {
    -ms-transform: translate(0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .css-menubar .site-menubar-flipped .page,
  .css-menubar .site-menubar-flipped .site-footer {
    margin-right: 90px;
    margin-left: 0;
  }
  .css-menubar .site-menubar-flipped .site-menu > .site-menu-item > .site-menu-sub {
    left: auto;
    right: 90px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) and (min-width: 768px) {
  .css-menubar .site-menubar-flipped .site-navbar .navbar-container {
    margin-right: 90px;
    margin-left: 0;
  }
}
.site-menubar-fold.site-menubar-flipped .page,
.site-menubar-fold.site-menubar-flipped .site-footer {
  margin-right: 90px;
  margin-left: 0;
}
@media (min-width: 768px) {
  .site-menubar-fold.site-menubar-flipped .site-navbar .navbar-container {
    margin-right: 90px;
    margin-left: 0;
  }
}
.site-menubar-fold.site-menubar-flipped .site-menu > .site-menu-item > .site-menu-sub {
  left: auto;
  right: 90px;
}
@media (max-width: 767px) {
  .site-menubar-flipped .site-menubar {
    -ms-transform: translate(260px, 0);
    -ms-transform: translate3d(260px, 0, 0);
    -webkit-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
}
/* fullcalendar Custom style
--------------------------------------------------------------------------------------------------*/
.fc td.fc-widget-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-width: 0px;
  font-size: 14px;
  text-transform: uppercase;
}
.fc td.fc-widget-content {
  border-width: 1px 0 0;
}
.fc td.fc-day.fc-widget-content + .fc-widget-content {
  border-left-width: 1px;
}
.fc .fc-row {
  border-width: 0;
}
.fc table > thead > tr > th {
  border-width: 0;
  font-weight: 100;
}
.fc .fc-toolbar h2 {
  display: inline-block;
  font-size: 20px;
  vertical-align: sub;
}
.fc .fc-axis.fc-time {
  text-transform: uppercase;
}
.fc .fc-toolbar {
  position: relative;
}
.fc-button.fc-prev-button,
.fc-button.fc-next-button {
  outline: none;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  border: 0px solid transparent;
}
.fc-button.fc-month-button,
.fc-button.fc-agendaWeek-button,
.fc-button.fc-agendaDay-button {
  height: auto;
  padding: 8px 14px;
  font-size: 14px;
  box-shadow: none;
  background-image: none;
  text-transform: capitalize;
  background-color: #f6f8f8;
  border-color: #e4eaec;
  outline: none;
}
.fc-button.fc-state-active,
.fc-button.fc-state-hover {
  background-color: #ffffff;
}
.fc-unthemed .fc-today {
  background-color: #f4f7f8;
}
.fc-toolbar .fc-right {
  position: absolute;
  right: 0;
  top: 0;
}
.fc-agendaWeek-view .fc-event,
.fc-agendaDay-view .fc-event {
  border-radius: 3px;
}
.fc-row.fc-widget-header {
  border-color: transparent;
}
.calendar-container {
  overflow: hidden;
}
.panel-heading {
  border-width: 0;
  padding-top: 10px;
}
.panel-body {
  padding-top: 10px;
}
.calendar-list .list-group-item {
  cursor: pointer;
}
.fc-event {
  border-radius: 10px;
  background-color: #62a8ea;
  border-color: #62a8ea;
}
.fc-day-grid-event .fc-content {
  line-height: 1em;
  padding: 2px 10px;
}
.fc-highlight {
  background: #f3f7f9;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  left: 3px;
}
@media (max-width: 991px) {
  .fc-toolbar {
    margin-bottom: 60px;
  }
  .fc-toolbar .fc-center {
    white-space: nowrap;
  }
  .fc-toolbar .fc-right {
    top: 50px;
  }
  .fc-button.fc-prev-button {
    padding-left: 0;
  }
  .fc-button.fc-next-button {
    padding-right: 0;
  }
}
.radio-custom,
.checkbox-custom {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio-custom label,
.checkbox-custom label {
  min-height: 22px;
  margin-bottom: 0;
  font-weight: 300;
  cursor: pointer;
}
.radio-custom input[type="radio"],
.checkbox-custom input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 0;
  margin-bottom: 0;
}
.radio-custom.disabled label,
.checkbox-custom.disabled label,
fieldset[disabled] .radio-custom label,
fieldset[disabled] .checkbox-custom label {
  cursor: not-allowed;
}
.input-group-addon .radio-custom,
.input-group-addon .checkbox-custom {
  margin: 0;
}
.checkbox-custom {
  padding-left: 20px;
}
.checkbox-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}
.checkbox-custom label:empty {
  padding-left: 0;
}
.checkbox-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.checkbox-custom label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  left: 0;
  top: 0;
  margin-left: -20px;
  text-align: center;
  padding-top: 1px;
  font-size: 12px;
  color: #76838f;
}
.checkbox-custom input[type="checkbox"],
.checkbox-custom input[type="radio"] {
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
}
.checkbox-custom input[type="checkbox"]:focus + label::before,
.checkbox-custom input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox-custom input[type="checkbox"]:checked + label::before,
.checkbox-custom input[type="radio"]:checked + label::before {
  border-color: #e4eaec;
  border-width: 10px;
  -webkit-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
}
.checkbox-custom input[type="checkbox"]:checked + label::after,
.checkbox-custom input[type="radio"]:checked + label::after {
  font-family: "Web Icons";
  content: "\f192";
}
.checkbox-custom input[type="checkbox"]:disabled + label,
.checkbox-custom input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.checkbox-custom input[type="checkbox"]:disabled + label::before,
.checkbox-custom input[type="radio"]:disabled + label::before {
  background-color: #f3f7f9;
  border-color: #e4eaec;
  border-width: 1px;
  cursor: not-allowed;
}
.checkbox-custom.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox-custom.checkbox-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}
.checkbox-inline + .checkbox-inline {
  margin-left: 20px;
}
.checkbox-default input[type="checkbox"]:checked + label::before,
.checkbox-default input[type="radio"]:checked + label::before {
  background-color: #ffffff;
  border-color: #e4eaec;
  border-width: 1px;
}
.checkbox-default input[type="checkbox"]:checked + label::after,
.checkbox-default input[type="radio"]:checked + label::after {
  color: #62a8ea;
}
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #62a8ea;
  border-color: #62a8ea;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #f96868;
  border-color: #f96868;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #57c7d4;
  border-color: #57c7d4;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f2a654;
  border-color: #f2a654;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #46be8a;
  border-color: #46be8a;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #ffffff;
}
.checkbox-sm {
  padding-left: 18px;
}
.checkbox-sm label {
  padding-left: 8px;
}
.checkbox-sm label:empty {
  padding-left: 0;
}
.checkbox-sm label::before,
.checkbox-sm label::after {
  width: 18px;
  height: 18px;
  margin-left: -18px;
}
.checkbox-sm label::after {
  font-size: 10px;
  line-height: 18px;
}
.checkbox-sm input[type="checkbox"],
.checkbox-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}
.checkbox-sm input[type="checkbox"]:checked + label::before,
.checkbox-sm input[type="radio"]:checked + label::before {
  border-width: 9px;
}
.checkbox-lg {
  padding-left: 24px;
}
.checkbox-lg label {
  padding-left: 12px;
}
.checkbox-lg label:empty {
  padding-left: 0;
}
.checkbox-lg label::before,
.checkbox-lg label::after {
  width: 24px;
  height: 24px;
  margin-left: -24px;
}
.checkbox-lg label::after {
  font-size: 14px;
  line-height: 24px;
}
.checkbox-lg input[type="checkbox"],
.checkbox-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}
.checkbox-lg input[type="checkbox"]:checked + label::before,
.checkbox-lg input[type="radio"]:checked + label::before {
  border-width: 12px;
}
.radio-custom {
  padding-left: 20px;
}
.radio-custom label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 10px;
}
.radio-custom label:empty {
  padding-left: 0;
}
.radio-custom label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #e4eaec;
  border-radius: 50%;
  background-color: #ffffff;
  -webkit-transition: border 300ms ease-in-out 0s, color 300ms ease-in-out 0s;
  -o-transition: border 300ms ease-in-out 0s, color 300ms ease-in-out 0s;
  transition: border 300ms ease-in-out 0s, color 300ms ease-in-out 0s;
}
.radio-custom label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 6px;
  height: 6px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border: 2px solid #76838f;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio-custom input[type="radio"] {
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 1;
}
.radio-custom input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.radio-custom input[type="radio"]:checked + label::before {
  border-color: #e4eaec;
  border-width: 10px;
}
.radio-custom input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio-custom input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio-custom input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio-custom.radio-inline {
  margin-top: 0;
  display: inline-block;
  margin-bottom: 0;
}
.radio-inline + .radio-inline {
  margin-left: 20px;
}
.radio-default input[type="radio"]:checked + label::before {
  background-color: #ffffff;
  border-color: #e4eaec;
  border-width: 1px;
}
.radio-default input[type="radio"]:checked + label::after {
  border-color: #62a8ea;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #62a8ea;
}
.radio-primary input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #f96868;
}
.radio-danger input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #57c7d4;
}
.radio-info input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f2a654;
}
.radio-warning input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #46be8a;
}
.radio-success input[type="radio"]:checked + label::after {
  border-color: #ffffff;
}
.radio-sm {
  padding-left: 18px;
}
.radio-sm label {
  padding-left: 8px;
}
.radio-sm label:empty {
  padding-left: 0;
}
.radio-sm label::before {
  width: 18px;
  height: 18px;
  margin-left: -20px;
}
.radio-sm label::after {
  width: 4px;
  height: 4px;
  left: 7px;
  top: 7px;
  margin-left: -20px;
  border-width: 2px;
}
.radio-sm input[type="radio"] {
  width: 18px;
  height: 18px;
}
.radio-sm input[type="radio"]:checked + label::before {
  border-width: 9px;
}
.radio-lg {
  padding-left: 24px;
}
.radio-lg label {
  padding-left: 12px;
}
.radio-lg label:empty {
  padding-left: 0;
}
.radio-lg label::before {
  width: 24px;
  height: 24px;
  margin-left: -20px;
}
.radio-lg label::after {
  width: 8px;
  height: 8px;
  left: 8px;
  top: 8px;
  margin-left: -20px;
  border-width: 2px;
}
.radio-lg input[type="radio"] {
  width: 24px;
  height: 24px;
}
.radio-lg input[type="radio"]:checked + label::before {
  border-width: 12px;
}
@media (min-width: 768px) {
  .form-inline .radio-custom,
  .form-inline .checkbox-custom {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio-custom label,
  .form-inline .checkbox-custom label {
    padding-left: 0;
  }
  .form-inline .radio-custom input[type="radio"],
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .radio-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom label {
    padding-left: 10px;
  }
  .form-inline .checkbox-custom input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
  }
  .form-inline .radio-custom input[type="radio"] {
    position: absolute;
    margin-left: -20px;
  }
}
.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
.form-horizontal .radio-custom,
.form-horizontal .checkbox-custom {
  min-height: 29px;
}
.form-material {
  position: relative;
}
.form-material.floating {
  margin-top: 20px;
  margin-bottom: 20px;
}
.form-material.floating + .form-material.floating {
  margin-top: 40px;
}
.form-material .form-control {
  padding: 0;
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
}
.form-material .form-control,
.form-material .form-control:focus,
.form-material .form-control.focus {
  float: none;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background-image: linear-gradient(#62a8ea, #62a8ea), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material .form-control {
  border-bottom: 2px solid #e4eaec;
}
.form-material .form-control::-webkit-input-placeholder {
  color: #a3afb7;
}
.form-material .form-control::-moz-placeholder {
  color: #a3afb7;
}
.form-material .form-control:-ms-input-placeholder {
  color: #a3afb7;
}
.form-material .form-control:disabled::-webkit-input-placeholder {
  color: #ccd5db;
}
.form-material .form-control:disabled::-moz-placeholder {
  color: #ccd5db;
}
.form-material .form-control:disabled:-ms-input-placeholder {
  color: #ccd5db;
}
.form-material .form-control:focus,
.form-material .form-control.focus {
  outline: none;
  background-size: 100% 2px, 100% 1px;
  transition-duration: 0.3s;
}
.no-cssgradients .form-material .form-control:focus,
.no-cssgradients .form-material .form-control.focus {
  background: transparent;
  border-bottom: 2px solid #62a8ea;
}
.form-material .form-control:disabled,
.form-material .form-control[disabled],
fieldset[disabled] .form-material .form-control {
  background: transparent;
  background: rgba(0, 0, 0, 0);
  border-bottom: 1px dashed #ccd5db;
}
.form-material .form-control:disabled ~ .floating-label,
.form-material .form-control[disabled] ~ .floating-label,
fieldset[disabled] .form-material .form-control ~ .floating-label {
  color: #ccd5db;
}
.form-material .control-label {
  font-weight: 500;
  margin-bottom: 0;
}
.form-material .floating-label {
  font-size: 14px;
  color: #76838f;
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: 0.3s ease all;
}
.form-material .floating-label.floating-label-static {
  position: relative;
  top: auto;
  display: block;
}
.form-material [class*="col-"] > .floating-label {
  left: 15px;
}
.form-material .form-control ~ .floating-label {
  font-size: 14px;
  top: 7.99999998px;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label,
.form-material .form-control:not(.empty) ~ .floating-label {
  top: -11.2px;
  font-size: 11.2px;
}
.form-material .form-control:-webkit-autofill ~ .floating-label {
  top: -11.2px;
  font-size: 11.2px;
}
.form-material .form-control.input-sm ~ .floating-label {
  font-size: 12px;
  top: 6px;
}
.form-material .form-control.input-sm:focus ~ .floating-label,
.form-material .form-control.input-sm.focus ~ .floating-label,
.form-material .form-control.input-sm:not(.empty) ~ .floating-label {
  top: -9.6px;
  font-size: 9.6px;
}
.form-material .form-control.input-sm:-webkit-autofill ~ .floating-label {
  top: -9.6px;
  font-size: 9.6px;
}
.form-material .form-control.input-lg ~ .floating-label {
  font-size: 18px;
  top: 5.9999994px;
}
.form-material .form-control.input-lg:focus ~ .floating-label,
.form-material .form-control.input-lg.focus ~ .floating-label,
.form-material .form-control.input-lg:not(.empty) ~ .floating-label {
  top: -14.4px;
  font-size: 14.4px;
}
.form-material .form-control.input-lg:-webkit-autofill ~ .floating-label {
  top: -14.4px;
  font-size: 14.4px;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label,
.form-material .form-control:not(.empty) ~ .floating-label {
  font-weight: 500;
}
.form-material .form-control:-webkit-autofill ~ .floating-label {
  font-weight: 500;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label {
  color: #62a8ea;
}
.form-material textarea.form-control {
  resize: none;
  padding-bottom: 6px;
}
.form-material.floating textarea.form-control {
  padding-top: 6px;
}
.form-material select.form-control {
  border: 0;
  border-radius: 0;
}
.form-material:not(.floating) .control-label + select[multiple] {
  margin-top: 5px;
}
.form-material .hint {
  position: absolute;
  font-size: 80%;
  display: none;
}
.form-material .form-control:focus ~ .hint,
.form-material .form-control.focus ~ .hint {
  display: block;
}
.form-material .form-control:not(.empty):invalid ~ .floating-label,
.form-material .form-control.focus:invalid ~ .floating-label {
  color: #f96868;
}
.form-material .form-control:invalid {
  background-image: linear-gradient(#f96868, #f96868), linear-gradient(#e4eaec, #e4eaec);
}
.form-material.form-group.has-warning .form-control:focus,
.form-material.form-group.has-warning .form-control.focus,
.form-material.form-group.has-warning .form-control:not(.empty) {
  background-image: linear-gradient(#f2a654, #f2a654), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-warning .form-control:focus,
.no-cssgradients .form-material.form-group.has-warning .form-control.focus,
.no-cssgradients .form-material.form-group.has-warning .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #f2a654;
}
.form-material.form-group.has-warning .form-control:-webkit-autofill {
  background-image: linear-gradient(#f2a654, #f2a654), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-warning .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #f2a654;
}
.form-material.form-group.has-warning .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-warning .control-label {
  color: #f2a654;
}
.form-material.form-group.has-warning .form-control:focus ~ .floating-label,
.form-material.form-group.has-warning .form-control.focus ~ .floating-label,
.form-material.form-group.has-warning .form-control:not(.empty) ~ .floating-label {
  color: #f2a654;
}
.form-material.form-group.has-warning .form-control:-webkit-autofill ~ .floating-label {
  color: #f2a654;
}
.form-material.form-group.has-error .form-control:focus,
.form-material.form-group.has-error .form-control.focus,
.form-material.form-group.has-error .form-control:not(.empty) {
  background-image: linear-gradient(#f96868, #f96868), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-error .form-control:focus,
.no-cssgradients .form-material.form-group.has-error .form-control.focus,
.no-cssgradients .form-material.form-group.has-error .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #f96868;
}
.form-material.form-group.has-error .form-control:-webkit-autofill {
  background-image: linear-gradient(#f96868, #f96868), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-error .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #f96868;
}
.form-material.form-group.has-error .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-error .control-label {
  color: #f96868;
}
.form-material.form-group.has-error .form-control:focus ~ .floating-label,
.form-material.form-group.has-error .form-control.focus ~ .floating-label,
.form-material.form-group.has-error .form-control:not(.empty) ~ .floating-label {
  color: #f96868;
}
.form-material.form-group.has-error .form-control:-webkit-autofill ~ .floating-label {
  color: #f96868;
}
.form-material.form-group.has-success .form-control:focus,
.form-material.form-group.has-success .form-control.focus,
.form-material.form-group.has-success .form-control:not(.empty) {
  background-image: linear-gradient(#46be8a, #46be8a), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-success .form-control:focus,
.no-cssgradients .form-material.form-group.has-success .form-control.focus,
.no-cssgradients .form-material.form-group.has-success .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #46be8a;
}
.form-material.form-group.has-success .form-control:-webkit-autofill {
  background-image: linear-gradient(#46be8a, #46be8a), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-success .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #46be8a;
}
.form-material.form-group.has-success .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-success .control-label {
  color: #46be8a;
}
.form-material.form-group.has-success .form-control:focus ~ .floating-label,
.form-material.form-group.has-success .form-control.focus ~ .floating-label,
.form-material.form-group.has-success .form-control:not(.empty) ~ .floating-label {
  color: #46be8a;
}
.form-material.form-group.has-success .form-control:-webkit-autofill ~ .floating-label {
  color: #46be8a;
}
.form-material.form-group.has-info .form-control:focus,
.form-material.form-group.has-info .form-control.focus,
.form-material.form-group.has-info .form-control:not(.empty) {
  background-image: linear-gradient(#57c7d4, #57c7d4), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-info .form-control:focus,
.no-cssgradients .form-material.form-group.has-info .form-control.focus,
.no-cssgradients .form-material.form-group.has-info .form-control:not(.empty) {
  background: transparent;
  border-bottom: 2px solid #57c7d4;
}
.form-material.form-group.has-info .form-control:-webkit-autofill {
  background-image: linear-gradient(#57c7d4, #57c7d4), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material.form-group.has-info .form-control:-webkit-autofill {
  background: transparent;
  border-bottom: 2px solid #57c7d4;
}
.form-material.form-group.has-info .form-control:not(.empty) {
  background-size: 100% 2px, 100% 1px;
}
.form-material.form-group.has-info .control-label {
  color: #57c7d4;
}
.form-material.form-group.has-info .form-control:focus ~ .floating-label,
.form-material.form-group.has-info .form-control.focus ~ .floating-label,
.form-material.form-group.has-info .form-control:not(.empty) ~ .floating-label {
  color: #57c7d4;
}
.form-material.form-group.has-info .form-control:-webkit-autofill ~ .floating-label {
  color: #57c7d4;
}
.form-material .input-group .form-control-wrap {
  margin-right: 5px;
  margin-left: 5px;
}
.form-material .input-group .form-control-wrap .form-control {
  float: none;
}
.form-material .input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.form-material .input-group .input-group-btn .btn {
  border-radius: 4px;
  margin: 0;
}
.form-material input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.form-control-wrap {
  position: relative;
}
.loader {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  font-size: 40px;
  text-indent: -9999em;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.loader.loader-default {
  width: 1em;
  height: 1em;
  background-color: #a3afb7;
  border-radius: 100%;
  -webkit-animation: loader-default 1s infinite ease-in-out;
  -o-animation: loader-default 1s infinite ease-in-out;
  animation: loader-default 1s infinite ease-in-out;
}
.loader.loader-grill {
  width: .25em;
  height: .5em;
  background: #a3afb7;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader.loader-grill:before,
.loader.loader-grill:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #a3afb7;
  -webkit-animation: default-grill 1s infinite ease-in-out;
  -o-animation: default-grill 1s infinite ease-in-out;
  animation: default-grill 1s infinite ease-in-out;
}
.loader.loader-grill:before {
  left: -0.375em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader.loader-grill:after {
  left: .375em;
}
.loader.loader-circle {
  width: 1em;
  height: 1em;
  border-top: 0.125em solid rgba(163, 175, 183, 0.5);
  border-right: 0.125em solid rgba(163, 175, 183, 0.5);
  border-bottom: 0.125em solid rgba(163, 175, 183, 0.5);
  border-left: 0.125em solid #a3afb7;
  border-radius: 50%;
  -webkit-animation: loader-circle 1.1s infinite linear;
  -o-animation: loader-circle 1.1s infinite linear;
  animation: loader-circle 1.1s infinite linear;
}
.loader.loader-round-circle {
  width: 1em;
  height: 1em;
  font-size: 10px;
  border-radius: 50%;
  -webkit-animation: loader-round-circle 1.3s infinite linear;
  -o-animation: loader-round-circle 1.3s infinite linear;
  animation: loader-round-circle 1.3s infinite linear;
}
.loader.loader-tadpole {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  -webkit-animation: loader-tadpole 1.7s infinite ease;
  -o-animation: loader-tadpole 1.7s infinite ease;
  animation: loader-tadpole 1.7s infinite ease;
}
.loader.loader-ellipsis {
  width: .625em;
  height: .625em;
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader.loader-ellipsis:before,
.loader.loader-ellipsis:after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  -webkit-animation: loader-ellipsis 1.8s infinite ease-in-out;
  -o-animation: loader-ellipsis 1.8s infinite ease-in-out;
  animation: loader-ellipsis 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.loader.loader-ellipsis:before {
  left: -0.875em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader.loader-ellipsis:after {
  left: .875em;
}
.loader.loader-dot {
  width: 2em;
  height: 2em;
  -webkit-animation: loader-dot-rotate 2s infinite linear;
  -o-animation: loader-dot-rotate 2s infinite linear;
  animation: loader-dot-rotate 2s infinite linear;
}
.loader.loader-dot:before,
.loader.loader-dot:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 60%;
  content: '';
  background: #a3afb7;
  border-radius: 100%;
  -webkit-animation: loader-dot-bounce 2s infinite ease-in-out;
  -o-animation: loader-dot-bounce 2s infinite ease-in-out;
  animation: loader-dot-bounce 2s infinite ease-in-out;
}
.loader.loader-dot:after {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader.loader-bounce {
  width: 1.5em;
  height: 1.5em;
}
.loader.loader-bounce:before,
.loader.loader-bounce:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: #a3afb7;
  border-radius: 50%;
  opacity: .6;
  -webkit-animation: loader-bounce 2s infinite ease-in-out;
  -o-animation: loader-bounce 2s infinite ease-in-out;
  animation: loader-bounce 2s infinite ease-in-out;
}
.loader.loader-bounce:after {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.side-panel-loading,
body > .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20px;
}
.site-menubar-unfold > .loader {
  margin-left: 130px;
}
.site-menubar-fold > .loader {
  margin-left: 45px;
}
.site-menubar-hide.site-menubar-unfold > .loader {
  margin-left: 0;
}
@keyframes loader-default {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes default-grill {
  0%,
  80%,
  100% {
    height: 1em;
    box-shadow: 0 0 #a3afb7;
  }
  40% {
    height: 1.2em;
    box-shadow: 0 -0.25em #a3afb7;
  }
}
@keyframes loader-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-round-circle {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 -0.5em #a3afb7, 2em 2em 0 -0.5em #a3afb7, 0 3em 0 -0.5em #a3afb7, -2em 2em 0 -0.5em #a3afb7, -3em 0 0 -0.5em #a3afb7, -2em -2em 0 0 #a3afb7;
  }
  12.5% {
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 0.2em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 -0.5em #a3afb7, 0 3em 0 -0.5em #a3afb7, -2em 2em 0 -0.5em #a3afb7, -3em 0 0 -0.5em #a3afb7, -2em -2em 0 -0.5em #a3afb7;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em #a3afb7, 2em -2em 0 0 #a3afb7, 3em 0 0 0.2em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 -0.5em #a3afb7, -2em 2em 0 -0.5em #a3afb7, -3em 0 0 -0.5em #a3afb7, -2em -2em 0 -0.5em #a3afb7;
  }
  37.5% {
    box-shadow: 0 -3em 0 -0.5em #a3afb7, 2em -2em 0 -0.5em #a3afb7, 3em 0 0 0 #a3afb7, 2em 2em 0 0.2em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 -0.5em #a3afb7, -3em 0 0 -0.5em #a3afb7, -2em -2em 0 -0.5em #a3afb7;
  }
  50% {
    box-shadow: 0 -3em 0 -0.5em #a3afb7, 2em -2em 0 -0.5em #a3afb7, 3em 0 0 -0.5em #a3afb7, 2em 2em 0 0 #a3afb7, 0 3em 0 0.2em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 -0.5em #a3afb7, -2em -2em 0 -0.5em #a3afb7;
  }
  62.5% {
    box-shadow: 0 -3em 0 -0.5em #a3afb7, 2em -2em 0 -0.5em #a3afb7, 3em 0 0 -0.5em #a3afb7, 2em 2em 0 -0.5em #a3afb7, 0 3em 0 0 #a3afb7, -2em 2em 0 0.2em #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 -0.5em #a3afb7;
  }
  75% {
    box-shadow: 0 -3em 0 -0.5em #a3afb7, 2em -2em 0 -0.5em #a3afb7, 3em 0 0 -0.5em #a3afb7, 2em 2em 0 -0.5em #a3afb7, 0 3em 0 -0.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0.2em #a3afb7, -2em -2em 0 0 #a3afb7;
  }
  87.5% {
    box-shadow: 0 -3em 0 0 #a3afb7, 2em -2em 0 -0.5em #a3afb7, 3em 0 0 -0.5em #a3afb7, 2em 2em 0 -0.5em #a3afb7, 0 3em 0 -0.5em #a3afb7, -2em 2em 0 0 #a3afb7, -3em 0 0 0 #a3afb7, -2em -2em 0 0.2em #a3afb7;
  }
}
@keyframes loader-tadpole {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, 0 -0.83em 0 -0.42em #a3afb7, 0 -0.83em 0 -0.44em #a3afb7, 0 -0.83em 0 -0.46em #a3afb7, 0 -0.83em 0 -0.477em #a3afb7;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, 0 -0.83em 0 -0.42em #a3afb7, 0 -0.83em 0 -0.44em #a3afb7, 0 -0.83em 0 -0.46em #a3afb7, 0 -0.83em 0 -0.477em #a3afb7;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, -0.087em -0.825em 0 -0.42em #a3afb7, -0.173em -0.812em 0 -0.44em #a3afb7, -0.256em -0.789em 0 -0.46em #a3afb7, -0.297em -0.775em 0 -0.477em #a3afb7;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, -0.338em -0.758em 0 -0.42em #a3afb7, -0.555em -0.617em 0 -0.44em #a3afb7, -0.671em -0.488em 0 -0.46em #a3afb7, -0.749em -0.34em 0 -0.477em #a3afb7;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, -0.377em -0.74em 0 -0.42em #a3afb7, -0.645em -0.522em 0 -0.44em #a3afb7, -0.775em -0.297em 0 -0.46em #a3afb7, -0.82em -0.09em 0 -0.477em #a3afb7;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em #a3afb7, 0 -0.83em 0 -0.42em #a3afb7, 0 -0.83em 0 -0.44em #a3afb7, 0 -0.83em 0 -0.46em #a3afb7, 0 -0.83em 0 -0.477em #a3afb7;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-ellipsis {
  0%,
  80%,
  100% {
    box-shadow: 0 0.625em 0 -0.325em #a3afb7;
  }
  40% {
    box-shadow: 0 0.625em 0 0 #a3afb7;
  }
}
@keyframes loader-dot-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-dot-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes loader-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
[class*=animation-] {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animation-hover:not(:hover),
.animation-hover:not(:hover) [class*=animation-],
.touch .animation-hover:not(.hover),
.touch .animation-hover:not(.hover) [class*=animation-] {
  -webkit-animation-name: none;
  animation-name: none;
}
.animation-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.animation-repeat {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.animation-fade {
  -webkit-animation-name: fade;
  animation-name: fade;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.animation-scale {
  -webkit-animation-name: scale-12;
  animation-name: scale-12;
}
.animation-scale-up {
  -webkit-animation-name: fade-scale-02;
  animation-name: fade-scale-02;
}
.animation-scale-down {
  -webkit-animation-name: fade-scale-18;
  animation-name: fade-scale-18;
}
.animation-slide-top {
  -webkit-animation-name: slide-top;
  animation-name: slide-top;
}
.animation-slide-bottom {
  -webkit-animation-name: slide-bottom;
  animation-name: slide-bottom;
}
.animation-slide-left {
  -webkit-animation-name: slide-left;
  animation-name: slide-left;
}
.animation-slide-right {
  -webkit-animation-name: slide-right;
  animation-name: slide-right;
}
.animation-shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}
.animation-duration-10 {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
}
.animation-duration-9 {
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
}
.animation-duration-8 {
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
}
.animation-duration-7 {
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
}
.animation-duration-6 {
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
}
.animation-duration-5 {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}
.animation-duration-4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}
.animation-duration-3 {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.animation-duration-2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animation-duration-1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.animation-delay-100 {
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
}
.animation-duration-250 {
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}
.animation-duration-300 {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
}
.animation-duration-500 {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}
.animation-duration-750 {
  -webkit-animation-duration: 750ms;
  animation-duration: 750ms;
}
.animation-delay-1000 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.animation-delay-900 {
  -webkit-animation-delay: 900ms;
  animation-delay: 900ms;
}
.animation-delay-800 {
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.animation-delay-700 {
  -webkit-animation-delay: 700ms;
  animation-delay: 700ms;
}
.animation-delay-600 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}
.animation-delay-500 {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}
.animation-delay-400 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.animation-delay-300 {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}
.animation-delay-200 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.animation-delay-100 {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}
.animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.animation-middle-left {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.animation-easing-easeInOut {
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
.animation-easing-easeInQuad {
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.animation-easing-easeInCubic {
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.animation-easing-easeInQuart {
  -webkit-animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.animation-easing-easeInQuint {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.animation-easing-easeInSine {
  -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}
.animation-easing-easeInExpo {
  -webkit-animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
}
.animation-easing-easeInCirc {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
}
.animation-easing-easeInBack {
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.animation-easing-eastOutQuad {
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.animation-easing-easeOutCubic {
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.animation-easing-easeOutQuart {
  -webkit-animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.animation-easing-easeOutQuint {
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.animation-easing-easeOutSine {
  -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.animation-easing-easeOutExpo {
  -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.animation-easing-easeOutCirc {
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.animation-easing-easeOutBack {
  -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.animation-easing-easeInOutQuad {
  -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.animation-easing-easeInOutCubic {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.animation-easing-easeInOutQuart {
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.animation-easing-easeInOutQuint {
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.animation-easing-easeInOutSine {
  -webkit-animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.animation-easing-easeInOutExpo {
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
}
.animation-easing-easeInOutCirc {
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.animation-easing-easeInOutBack {
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.animation-easing-easeInOutElastic {
  -webkit-animation-timing-function: cubic-bezier(1, -0.56, 0, 1.455);
  animation-timing-function: cubic-bezier(1, -0.56, 0, 1.455);
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-12 {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-12 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
    transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
    transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
.primary-100 {
  color: #e8f1f8 !important;
}
.primary-200 {
  color: #d5e4f1 !important;
}
.primary-300 {
  color: #bcd8f1 !important;
}
.primary-400 {
  color: #a2caee !important;
}
.primary-500 {
  color: #89bceb !important;
}
.primary-600 {
  color: #62a8ea !important;
}
.primary-700 {
  color: #4e97d9 !important;
}
.primary-800 {
  color: #3583ca !important;
}
.red-100 {
  color: #ffeaea !important;
}
.red-200 {
  color: #fad3d3 !important;
}
.red-300 {
  color: #fab4b4 !important;
}
.red-400 {
  color: #fa9898 !important;
}
.red-500 {
  color: #fa7a7a !important;
}
.red-600 {
  color: #f96868 !important;
}
.red-700 {
  color: #e9595b !important;
}
.red-800 {
  color: #d6494b !important;
}
.pink-100 {
  color: #fce4ec !important;
}
.pink-200 {
  color: #ffccde !important;
}
.pink-300 {
  color: #fba9c6 !important;
}
.pink-400 {
  color: #fb8db4 !important;
}
.pink-500 {
  color: #f978a6 !important;
}
.pink-600 {
  color: #f96197 !important;
}
.pink-700 {
  color: #f44c87 !important;
}
.pink-800 {
  color: #e53b75 !important;
}
.purple-100 {
  color: #f6f2ff !important;
}
.purple-200 {
  color: #e3dbf4 !important;
}
.purple-300 {
  color: #d2c5ec !important;
}
.purple-400 {
  color: #bba7e4 !important;
}
.purple-500 {
  color: #a58add !important;
}
.purple-600 {
  color: #926dde !important;
}
.purple-700 {
  color: #7c51d1 !important;
}
.purple-800 {
  color: #6d45bc !important;
}
.indigo-100 {
  color: #edeff9 !important;
}
.indigo-200 {
  color: #dadef5 !important;
}
.indigo-300 {
  color: #bcc5f4 !important;
}
.indigo-400 {
  color: #9daaf3 !important;
}
.indigo-500 {
  color: #8897ec !important;
}
.indigo-600 {
  color: #677ae4 !important;
}
.indigo-700 {
  color: #5166d6 !important;
}
.indigo-800 {
  color: #465bd4 !important;
}
.blue-100 {
  color: #e8f1f8 !important;
}
.blue-200 {
  color: #d5e4f1 !important;
}
.blue-300 {
  color: #bcd8f1 !important;
}
.blue-400 {
  color: #a2caee !important;
}
.blue-500 {
  color: #89bceb !important;
}
.blue-600 {
  color: #62a8ea !important;
}
.blue-700 {
  color: #4e97d9 !important;
}
.blue-800 {
  color: #3583ca !important;
}
.cyan-100 {
  color: #ecf9fa !important;
}
.cyan-200 {
  color: #d3eff2 !important;
}
.cyan-300 {
  color: #baeaef !important;
}
.cyan-400 {
  color: #9ae1e9 !important;
}
.cyan-500 {
  color: #77d6e1 !important;
}
.cyan-600 {
  color: #57c7d4 !important;
}
.cyan-700 {
  color: #47b8c6 !important;
}
.cyan-800 {
  color: #37a9b7 !important;
}
.teal-100 {
  color: #ecfdfc !important;
}
.teal-200 {
  color: #cdf4f1 !important;
}
.teal-300 {
  color: #99e1da !important;
}
.teal-400 {
  color: #79d1c9 !important;
}
.teal-500 {
  color: #56bfb5 !important;
}
.teal-600 {
  color: #3aa99e !important;
}
.teal-700 {
  color: #269b8f !important;
}
.teal-800 {
  color: #178d81 !important;
}
.green-100 {
  color: #e7faf2 !important;
}
.green-200 {
  color: #bfedd8 !important;
}
.green-300 {
  color: #9fe5c5 !important;
}
.green-400 {
  color: #7dd3ae !important;
}
.green-500 {
  color: #5cd29d !important;
}
.green-600 {
  color: #46be8a !important;
}
.green-700 {
  color: #36ab7a !important;
}
.green-800 {
  color: #279566 !important;
}
.light-green-100 {
  color: #f1f7ea !important;
}
.light-green-200 {
  color: #e0ecd1 !important;
}
.light-green-300 {
  color: #cadfb1 !important;
}
.light-green-400 {
  color: #bad896 !important;
}
.light-green-500 {
  color: #acd57c !important;
}
.light-green-600 {
  color: #9ece67 !important;
}
.light-green-700 {
  color: #83b944 !important;
}
.light-green-800 {
  color: #70a532 !important;
}
.yellow-100 {
  color: #fffae7 !important;
}
.yellow-200 {
  color: #f9eec1 !important;
}
.yellow-300 {
  color: #f6e7a9 !important;
}
.yellow-400 {
  color: #f8e59b !important;
}
.yellow-500 {
  color: #f7e083 !important;
}
.yellow-600 {
  color: #f7da64 !important;
}
.yellow-700 {
  color: #f9cd48 !important;
}
.yellow-800 {
  color: #fbc02d !important;
}
.orange-100 {
  color: #fff3e6 !important;
}
.orange-200 {
  color: #ffddb9 !important;
}
.orange-300 {
  color: #fbce9d !important;
}
.orange-400 {
  color: #f6be80 !important;
}
.orange-500 {
  color: #f4b066 !important;
}
.orange-600 {
  color: #f2a654 !important;
}
.orange-700 {
  color: #ec9940 !important;
}
.orange-800 {
  color: #e98f2e !important;
}
.brown-100 {
  color: #fae6df !important;
}
.brown-200 {
  color: #e2bdaf !important;
}
.brown-300 {
  color: #d3aa9c !important;
}
.brown-400 {
  color: #b98e7e !important;
}
.brown-500 {
  color: #a17768 !important;
}
.brown-600 {
  color: #8d6658 !important;
}
.brown-700 {
  color: #7d5b4f !important;
}
.brown-800 {
  color: #715146 !important;
}
.grey-100 {
  color: #fafafa !important;
}
.grey-200 {
  color: #eeeeee !important;
}
.grey-300 {
  color: #e0e0e0 !important;
}
.grey-400 {
  color: #bdbdbd !important;
}
.grey-500 {
  color: #9e9e9e !important;
}
.grey-600 {
  color: #757575 !important;
}
.grey-700 {
  color: #616161 !important;
}
.grey-800 {
  color: #424242 !important;
}
.blue-grey-100 {
  color: #f3f7f9 !important;
}
.blue-grey-200 {
  color: #e4eaec !important;
}
.blue-grey-300 {
  color: #ccd5db !important;
}
.blue-grey-400 {
  color: #a3afb7 !important;
}
.blue-grey-500 {
  color: #76838f !important;
}
.blue-grey-600 {
  color: #526069 !important;
}
.blue-grey-700 {
  color: #37474f !important;
}
.blue-grey-800 {
  color: #263238 !important;
}
.bg-primary-100 {
  background-color: #e8f1f8 !important;
}
.bg-primary-200 {
  background-color: #d5e4f1 !important;
}
.bg-primary-300 {
  background-color: #bcd8f1 !important;
}
.bg-primary-400 {
  background-color: #a2caee !important;
}
.bg-primary-500 {
  background-color: #89bceb !important;
}
.bg-primary-600 {
  background-color: #62a8ea !important;
}
.bg-primary-700 {
  background-color: #4e97d9 !important;
}
.bg-primary-800 {
  background-color: #3583ca !important;
}
.bg-red-100 {
  background-color: #ffeaea !important;
}
.bg-red-200 {
  background-color: #fad3d3 !important;
}
.bg-red-300 {
  background-color: #fab4b4 !important;
}
.bg-red-400 {
  background-color: #fa9898 !important;
}
.bg-red-500 {
  background-color: #fa7a7a !important;
}
.bg-red-600 {
  background-color: #f96868 !important;
}
.bg-red-700 {
  background-color: #e9595b !important;
}
.bg-red-800 {
  background-color: #d6494b !important;
}
.bg-pink-100 {
  background-color: #fce4ec !important;
}
.bg-pink-200 {
  background-color: #ffccde !important;
}
.bg-pink-300 {
  background-color: #fba9c6 !important;
}
.bg-pink-400 {
  background-color: #fb8db4 !important;
}
.bg-pink-500 {
  background-color: #f978a6 !important;
}
.bg-pink-600 {
  background-color: #f96197 !important;
}
.bg-pink-700 {
  background-color: #f44c87 !important;
}
.bg-pink-800 {
  background-color: #e53b75 !important;
}
.bg-purple-100 {
  background-color: #f6f2ff !important;
}
.bg-purple-200 {
  background-color: #e3dbf4 !important;
}
.bg-purple-300 {
  background-color: #d2c5ec !important;
}
.bg-purple-400 {
  background-color: #bba7e4 !important;
}
.bg-purple-500 {
  background-color: #a58add !important;
}
.bg-purple-600 {
  background-color: #926dde !important;
}
.bg-purple-700 {
  background-color: #7c51d1 !important;
}
.bg-purple-800 {
  background-color: #6d45bc !important;
}
.bg-indigo-100 {
  background-color: #edeff9 !important;
}
.bg-indigo-200 {
  background-color: #dadef5 !important;
}
.bg-indigo-300 {
  background-color: #bcc5f4 !important;
}
.bg-indigo-400 {
  background-color: #9daaf3 !important;
}
.bg-indigo-500 {
  background-color: #8897ec !important;
}
.bg-indigo-600 {
  background-color: #677ae4 !important;
}
.bg-indigo-700 {
  background-color: #5166d6 !important;
}
.bg-indigo-800 {
  background-color: #465bd4 !important;
}
.bg-blue-100 {
  background-color: #e8f1f8 !important;
}
.bg-blue-200 {
  background-color: #d5e4f1 !important;
}
.bg-blue-300 {
  background-color: #bcd8f1 !important;
}
.bg-blue-400 {
  background-color: #a2caee !important;
}
.bg-blue-500 {
  background-color: #89bceb !important;
}
.bg-blue-600 {
  background-color: #62a8ea !important;
}
.bg-blue-700 {
  background-color: #4e97d9 !important;
}
.bg-blue-800 {
  background-color: #3583ca !important;
}
.bg-cyan-100 {
  background-color: #ecf9fa !important;
}
.bg-cyan-200 {
  background-color: #d3eff2 !important;
}
.bg-cyan-300 {
  background-color: #baeaef !important;
}
.bg-cyan-400 {
  background-color: #9ae1e9 !important;
}
.bg-cyan-500 {
  background-color: #77d6e1 !important;
}
.bg-cyan-600 {
  background-color: #57c7d4 !important;
}
.bg-cyan-700 {
  background-color: #47b8c6 !important;
}
.bg-cyan-800 {
  background-color: #37a9b7 !important;
}
.bg-teal-100 {
  background-color: #ecfdfc !important;
}
.bg-teal-200 {
  background-color: #cdf4f1 !important;
}
.bg-teal-300 {
  background-color: #99e1da !important;
}
.bg-teal-400 {
  background-color: #79d1c9 !important;
}
.bg-teal-500 {
  background-color: #56bfb5 !important;
}
.bg-teal-600 {
  background-color: #3aa99e !important;
}
.bg-teal-700 {
  background-color: #269b8f !important;
}
.bg-teal-800 {
  background-color: #178d81 !important;
}
.bg-green-100 {
  background-color: #e7faf2 !important;
}
.bg-green-200 {
  background-color: #bfedd8 !important;
}
.bg-green-300 {
  background-color: #9fe5c5 !important;
}
.bg-green-400 {
  background-color: #7dd3ae !important;
}
.bg-green-500 {
  background-color: #5cd29d !important;
}
.bg-green-600 {
  background-color: #46be8a !important;
}
.bg-green-700 {
  background-color: #36ab7a !important;
}
.bg-green-800 {
  background-color: #279566 !important;
}
.bg-light-green-100 {
  background-color: #f1f7ea !important;
}
.bg-light-green-200 {
  background-color: #e0ecd1 !important;
}
.bg-light-green-300 {
  background-color: #cadfb1 !important;
}
.bg-light-green-400 {
  background-color: #bad896 !important;
}
.bg-light-green-500 {
  background-color: #acd57c !important;
}
.bg-light-green-600 {
  background-color: #9ece67 !important;
}
.bg-light-green-700 {
  background-color: #83b944 !important;
}
.bg-light-green-800 {
  background-color: #70a532 !important;
}
.bg-yellow-100 {
  background-color: #fffae7 !important;
}
.bg-yellow-200 {
  background-color: #f9eec1 !important;
}
.bg-yellow-300 {
  background-color: #f6e7a9 !important;
}
.bg-yellow-400 {
  background-color: #f8e59b !important;
}
.bg-yellow-500 {
  background-color: #f7e083 !important;
}
.bg-yellow-600 {
  background-color: #f7da64 !important;
}
.bg-yellow-700 {
  background-color: #f9cd48 !important;
}
.bg-yellow-800 {
  background-color: #fbc02d !important;
}
.bg-orange-100 {
  background-color: #fff3e6 !important;
}
.bg-orange-200 {
  background-color: #ffddb9 !important;
}
.bg-orange-300 {
  background-color: #fbce9d !important;
}
.bg-orange-400 {
  background-color: #f6be80 !important;
}
.bg-orange-500 {
  background-color: #f4b066 !important;
}
.bg-orange-600 {
  background-color: #f2a654 !important;
}
.bg-orange-700 {
  background-color: #ec9940 !important;
}
.bg-orange-800 {
  background-color: #e98f2e !important;
}
.bg-brown-100 {
  background-color: #fae6df !important;
}
.bg-brown-200 {
  background-color: #e2bdaf !important;
}
.bg-brown-300 {
  background-color: #d3aa9c !important;
}
.bg-brown-400 {
  background-color: #b98e7e !important;
}
.bg-brown-500 {
  background-color: #a17768 !important;
}
.bg-brown-600 {
  background-color: #8d6658 !important;
}
.bg-brown-700 {
  background-color: #7d5b4f !important;
}
.bg-brown-800 {
  background-color: #715146 !important;
}
.bg-grey-100 {
  background-color: #fafafa !important;
}
.bg-grey-200 {
  background-color: #eeeeee !important;
}
.bg-grey-300 {
  background-color: #e0e0e0 !important;
}
.bg-grey-400 {
  background-color: #bdbdbd !important;
}
.bg-grey-500 {
  background-color: #9e9e9e !important;
}
.bg-grey-600 {
  background-color: #757575 !important;
}
.bg-grey-700 {
  background-color: #616161 !important;
}
.bg-grey-800 {
  background-color: #424242 !important;
}
.bg-blue-grey-100 {
  background-color: #f3f7f9 !important;
}
.bg-blue-grey-200 {
  background-color: #e4eaec !important;
}
.bg-blue-grey-300 {
  background-color: #ccd5db !important;
}
.bg-blue-grey-400 {
  background-color: #a3afb7 !important;
}
.bg-blue-grey-500 {
  background-color: #76838f !important;
}
.bg-blue-grey-600 {
  background-color: #526069 !important;
}
.bg-blue-grey-700 {
  background-color: #37474f !important;
}
.bg-blue-grey-800 {
  background-color: #263238 !important;
}
.black {
  color: #000000 !important;
}
.white {
  color: #ffffff !important;
}
.bg-white {
  color: #76838f;
  background-color: #ffffff;
}
.bg-primary {
  color: #ffffff;
  background-color: #62a8ea;
}
.bg-primary:hover {
  background-color: #8fc1f0;
}
.bg-primary a,
a.bg-primary {
  color: #ffffff;
}
.bg-primary a:hover,
a.bg-primary:hover {
  color: #ffffff;
}
.bg-success {
  color: #ffffff;
  background-color: #46be8a;
}
.bg-success:hover {
  background-color: #6ccba2;
}
.bg-success a,
a.bg-success {
  color: #ffffff;
}
.bg-success a:hover,
a.bg-success:hover {
  color: #ffffff;
}
.bg-info {
  color: #ffffff;
  background-color: #57c7d4;
}
.bg-info:hover {
  background-color: #80d5de;
}
.bg-info a,
a.bg-info {
  color: #ffffff;
}
.bg-info a:hover,
a.bg-info:hover {
  color: #ffffff;
}
.bg-warning {
  color: #ffffff;
  background-color: #f2a654;
}
.bg-warning:hover {
  background-color: #f6bf83;
}
.bg-warning a,
a.bg-warning {
  color: #ffffff;
}
.bg-warning a:hover,
a.bg-warning:hover {
  color: #ffffff;
}
.bg-danger {
  color: #ffffff;
  background-color: #f96868;
}
.bg-danger:hover {
  background-color: #fb9999;
}
.bg-danger a,
a.bg-danger {
  color: #ffffff;
}
.bg-danger a:hover,
a.bg-danger:hover {
  color: #ffffff;
}
.bg-dark {
  color: #ffffff;
  background-color: #526069;
}
.bg-dark:hover {
  background-color: #687a86;
}
.bg-dark a,
a.bg-dark {
  color: #ffffff;
}
.bg-dark a:hover,
a.bg-dark:hover {
  color: #ffffff;
}
.social-facebook {
  color: #ffffff;
  background-color: #3B5998 !important;
}
.social-facebook:hover,
.social-facebook:focus {
  color: #ffffff;
  background-color: #4c70ba !important;
}
.social-facebook:active,
.social-facebook.active {
  color: #ffffff;
  background-color: #2d4373 !important;
}
.bg-facebook {
  background-color: #3B5998;
}
.social-twitter {
  color: #ffffff;
  background-color: #55ACEE !important;
}
.social-twitter:hover,
.social-twitter:focus {
  color: #ffffff;
  background-color: #83c3f3 !important;
}
.social-twitter:active,
.social-twitter.active {
  color: #ffffff;
  background-color: #2795e9 !important;
}
.bg-twitter {
  background-color: #55ACEE;
}
.social-google-plus {
  color: #ffffff;
  background-color: #DD4B39 !important;
}
.social-google-plus:hover,
.social-google-plus:focus {
  color: #ffffff;
  background-color: #e47365 !important;
}
.social-google-plus:active,
.social-google-plus.active {
  color: #ffffff;
  background-color: #c23321 !important;
}
.bg-google-plus {
  background-color: #DD4B39;
}
.social-linkedin {
  color: #ffffff;
  background-color: #0976B4 !important;
}
.social-linkedin:hover,
.social-linkedin:focus {
  color: #ffffff;
  background-color: #0b96e5 !important;
}
.social-linkedin:active,
.social-linkedin.active {
  color: #ffffff;
  background-color: #075683 !important;
}
.bg-linkedin {
  background-color: #0976B4;
}
.social-flickr {
  color: #ffffff;
  background-color: #FF0084 !important;
}
.social-flickr:hover,
.social-flickr:focus {
  color: #ffffff;
  background-color: #ff339d !important;
}
.social-flickr:active,
.social-flickr.active {
  color: #ffffff;
  background-color: #cc006a !important;
}
.bg-flickr {
  background-color: #FF0084;
}
.social-tumblr {
  color: #ffffff;
  background-color: #35465C !important;
}
.social-tumblr:hover,
.social-tumblr:focus {
  color: #ffffff;
  background-color: #485f7c !important;
}
.social-tumblr:active,
.social-tumblr.active {
  color: #ffffff;
  background-color: #222d3c !important;
}
.bg-tumblr {
  background-color: #35465C;
}
.social-xing {
  color: #ffffff;
  background-color: #024b4d !important;
}
.social-xing:hover,
.social-xing:focus {
  color: #ffffff;
  background-color: #037b7f !important;
}
.social-xing:active,
.social-xing.active {
  color: #ffffff;
  background-color: #011b1b !important;
}
.bg-xing {
  background-color: #024b4d;
}
.social-github {
  color: #ffffff;
  background-color: #4183c4 !important;
}
.social-github:hover,
.social-github:focus {
  color: #ffffff;
  background-color: #689cd0 !important;
}
.social-github:active,
.social-github.active {
  color: #ffffff;
  background-color: #3269a0 !important;
}
.bg-github {
  background-color: #4183c4;
}
.social-html5 {
  color: #ffffff;
  background-color: #E44F26 !important;
}
.social-html5:hover,
.social-html5:focus {
  color: #ffffff;
  background-color: #ea7453 !important;
}
.social-html5:active,
.social-html5.active {
  color: #ffffff;
  background-color: #bf3c18 !important;
}
.bg-html5 {
  background-color: #E44F26;
}
.social-openid {
  color: #ffffff;
  background-color: #f67d28 !important;
}
.social-openid:hover,
.social-openid:focus {
  color: #ffffff;
  background-color: #f89b59 !important;
}
.social-openid:active,
.social-openid.active {
  color: #ffffff;
  background-color: #e26309 !important;
}
.bg-openid {
  background-color: #f67d28;
}
.social-stack-overflow {
  color: #ffffff;
  background-color: #f86c01 !important;
}
.social-stack-overflow:hover,
.social-stack-overflow:focus {
  color: #ffffff;
  background-color: #fe882e !important;
}
.social-stack-overflow:active,
.social-stack-overflow.active {
  color: #ffffff;
  background-color: #c55601 !important;
}
.bg-stack-overflow {
  background-color: #f86c01;
}
.social-css3 {
  color: #ffffff;
  background-color: #1572B6 !important;
}
.social-css3:hover,
.social-css3:focus {
  color: #ffffff;
  background-color: #1a8fe4 !important;
}
.social-css3:active,
.social-css3.active {
  color: #ffffff;
  background-color: #105588 !important;
}
.bg-css3 {
  background-color: #1572B6;
}
.social-youtube {
  color: #ffffff;
  background-color: #B31217 !important;
}
.social-youtube:hover,
.social-youtube:focus {
  color: #ffffff;
  background-color: #e1171d !important;
}
.social-youtube:active,
.social-youtube.active {
  color: #ffffff;
  background-color: #850d11 !important;
}
.bg-youtube {
  background-color: #B31217;
}
.social-dribbble {
  color: #ffffff;
  background-color: #C32361 !important;
}
.social-dribbble:hover,
.social-dribbble:focus {
  color: #ffffff;
  background-color: #dc3d7b !important;
}
.social-dribbble:active,
.social-dribbble.active {
  color: #ffffff;
  background-color: #981b4b !important;
}
.bg-dribbble {
  background-color: #C32361;
}
.social-instagram {
  color: #ffffff;
  background-color: #3F729B !important;
}
.social-instagram:hover,
.social-instagram:focus {
  color: #ffffff;
  background-color: #548cb9 !important;
}
.social-instagram:active,
.social-instagram.active {
  color: #ffffff;
  background-color: #305777 !important;
}
.bg-instagram {
  background-color: #3F729B;
}
.social-pinterest {
  color: #ffffff;
  background-color: #CC2127 !important;
}
.social-pinterest:hover,
.social-pinterest:focus {
  color: #ffffff;
  background-color: #e04046 !important;
}
.social-pinterest:active,
.social-pinterest.active {
  color: #ffffff;
  background-color: #a01a1f !important;
}
.bg-pinterest {
  background-color: #CC2127;
}
.social-vk {
  color: #ffffff;
  background-color: #3d5a7d !important;
}
.social-vk:hover,
.social-vk:focus {
  color: #ffffff;
  background-color: #4e739f !important;
}
.social-vk:active,
.social-vk.active {
  color: #ffffff;
  background-color: #2c415b !important;
}
.bg-vk {
  background-color: #3d5a7d;
}
.social-yahoo {
  color: #ffffff;
  background-color: #350178 !important;
}
.social-yahoo:hover,
.social-yahoo:focus {
  color: #ffffff;
  background-color: #4b01ab !important;
}
.social-yahoo:active,
.social-yahoo.active {
  color: #ffffff;
  background-color: #1f0145 !important;
}
.bg-yahoo {
  background-color: #350178;
}
.social-behance {
  color: #ffffff;
  background-color: #1769FF !important;
}
.social-behance:hover,
.social-behance:focus {
  color: #ffffff;
  background-color: #4a8aff !important;
}
.social-behance:active,
.social-behance.active {
  color: #ffffff;
  background-color: #0050e3 !important;
}
.bg-behance {
  background-color: #024b4d;
}
.social-dropbox {
  color: #ffffff;
  background-color: #007EE5 !important;
}
.social-dropbox:hover,
.social-dropbox:focus {
  color: #ffffff;
  background-color: #1998ff !important;
}
.social-dropbox:active,
.social-dropbox.active {
  color: #ffffff;
  background-color: #0062b2 !important;
}
.bg-dropbox {
  background-color: #007EE5;
}
.social-reddit {
  color: #ffffff;
  background-color: #FF4500 !important;
}
.social-reddit:hover,
.social-reddit:focus {
  color: #ffffff;
  background-color: #ff6a33 !important;
}
.social-reddit:active,
.social-reddit.active {
  color: #ffffff;
  background-color: #cc3700 !important;
}
.bg-reddit {
  background-color: #FF4500;
}
.social-spotify {
  color: #ffffff;
  background-color: #7AB800 !important;
}
.social-spotify:hover,
.social-spotify:focus {
  color: #ffffff;
  background-color: #9ceb00 !important;
}
.social-spotify:active,
.social-spotify.active {
  color: #ffffff;
  background-color: #588500 !important;
}
.bg-spotify {
  background-color: #7AB800;
}
.social-vine {
  color: #ffffff;
  background-color: #00B488 !important;
}
.social-vine:hover,
.social-vine:focus {
  color: #ffffff;
  background-color: #00e7af !important;
}
.social-vine:active,
.social-vine.active {
  color: #ffffff;
  background-color: #008161 !important;
}
.bg-vine {
  background-color: #00B488;
}
.social-foursquare {
  color: #ffffff;
  background-color: #0cbadf !important;
}
.social-foursquare:hover,
.social-foursquare:focus {
  color: #ffffff;
  background-color: #2ad0f4 !important;
}
.social-foursquare:active,
.social-foursquare.active {
  color: #ffffff;
  background-color: #0992af !important;
}
.bg-foursquare {
  background-color: #0cbadf;
}
.social-vimeo {
  color: #ffffff;
  background-color: #1AB7EA !important;
}
.social-vimeo:hover,
.social-vimeo:focus {
  color: #ffffff;
  background-color: #49c6ee !important;
}
.social-vimeo:active,
.social-vimeo.active {
  color: #ffffff;
  background-color: #1295bf !important;
}
.bg-vimeo {
  background-color: #1AB7EA;
}
.social-skype {
  color: #ffffff;
  background-color: #77bcfd !important;
}
.social-skype:hover,
.social-skype:focus {
  color: #ffffff;
  background-color: #a9d5fe !important;
}
.social-skype:active,
.social-skype.active {
  color: #ffffff;
  background-color: #45a3fc !important;
}
.bg-skype {
  background-color: #77bcfd;
}
.social-evernote {
  color: #ffffff;
  background-color: #46bf8c !important;
}
.social-evernote:hover,
.social-evernote:focus {
  color: #ffffff;
  background-color: #6ccca4 !important;
}
.social-evernote:active,
.social-evernote.active {
  color: #ffffff;
  background-color: #369c71 !important;
}
.bg-evernote {
  background-color: #46bf8c;
}
a:focus {
  outline: none;
}
.blocks,
[class*="blocks-"] {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.blocks:before,
[class*="blocks-"]:before,
.blocks:after,
[class*="blocks-"]:after {
  content: " ";
  display: table;
}
.blocks:after,
[class*="blocks-"]:after {
  clear: both;
}
.blocks > li,
[class*="blocks-"] > li {
  margin-bottom: 22px;
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.blocks.no-space,
[class*="blocks-"].no-space {
  margin: 0;
}
.blocks.no-space > li,
[class*="blocks-"].no-space > li {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.blocks-2 > li:nth-child(2n+3),
.blocks-3 > li:nth-child(3n+4),
.blocks-4 > li:nth-child(4n+5),
.blocks-5 > li:nth-child(5n+6),
.blocks-6 > li:nth-child(6n+7),
.block-first {
  clear: both;
}
.blocks-100 > li {
  width: 100%;
}
.blocks-2 > li {
  width: 50%;
}
.blocks-3 > li {
  width: 33.33333333%;
}
.blocks-4 > li {
  width: 25%;
}
.blocks-5 > li {
  width: 20%;
}
.blocks-6 > li {
  width: 16.66666667%;
}
@media (min-width: 480px) {
  .blocks-xs-100 > li {
    width: 100%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-2 > li {
    width: 50%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-4 > li {
    width: 25%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-5 > li {
    width: 20%;
  }
}
@media (min-width: 480px) {
  .blocks-xs-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-100 > li {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-2 > li {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-4 > li {
    width: 25%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-5 > li {
    width: 20%;
  }
}
@media (min-width: 768px) {
  .blocks-sm-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 992px) {
  .blocks-md-100 > li {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .blocks-md-2 > li {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .blocks-md-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 992px) {
  .blocks-md-4 > li {
    width: 25%;
  }
}
@media (min-width: 992px) {
  .blocks-md-5 > li {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .blocks-md-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-100 > li {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-2 > li {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-4 > li {
    width: 25%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-5 > li {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .blocks-lg-6 > li {
    width: 16.66666667%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-100 > li {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-2 > li {
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-3 > li {
    width: 33.33333333%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-4 > li {
    width: 25%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-5 > li {
    width: 20%;
  }
}
@media (min-width: 1600px) {
  .blocks-xlg-6 > li {
    width: 16.66666667%;
  }
}
.avatar {
  position: relative;
  display: inline-block;
  width: 40px;
  white-space: nowrap;
  border-radius: 1000px;
  vertical-align: bottom;
}
.avatar i {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}
.avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0 none;
  border-radius: 1000px;
}
.avatar-online i {
  background-color: #46be8a;
}
.avatar-off i {
  background-color: #526069;
}
.avatar-busy i {
  background-color: #f2a654;
}
.avatar-away i {
  background-color: #f96868;
}
.avatar-100 {
  width: 100px;
}
.avatar-100 i {
  height: 20px;
  width: 20px;
}
.avatar-lg {
  width: 50px;
}
.avatar-lg i {
  height: 12px;
  width: 12px;
}
.avatar-sm {
  width: 30px;
}
.avatar-sm i {
  height: 8px;
  width: 8px;
}
.avatar-xs {
  width: 20px;
}
.avatar-xs i {
  height: 7px;
  width: 7px;
}
.status {
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  border-radius: 100%;
}
.status-online {
  background-color: #46be8a;
}
.status-off {
  background-color: #526069;
}
.status-busy {
  background-color: #f2a654;
}
.status-away {
  background-color: #f96868;
}
.status-lg {
  width: 14px;
  height: 14px;
}
.status-md {
  width: 10px;
  height: 10px;
}
.status-sm {
  width: 8px;
  height: 8px;
}
.status-xs {
  width: 7px;
  height: 7px;
}
.icon {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  line-height: 1;
}
.icon.pull-left {
  margin-right: .3em;
}
.icon.pull-right {
  margin-left: .3em;
}
.icon.icon-circle {
  position: relative;
  margin: .5em;
}
.icon.icon-circle:before {
  position: relative;
  z-index: 1;
}
.icon.icon-circle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  content: "";
  width: 2em;
  height: 2em;
  background-color: inherit;
  border-radius: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.icon.icon-lg {
  font-size: 1.33333333em;
  vertical-align: -15%;
}
.icon.icon-2x {
  font-size: 2em;
}
.icon.icon-3x {
  font-size: 3em;
}
.icon.icon-4x {
  font-size: 4em;
}
.icon.icon-5x {
  font-size: 5em;
}
.icon.icon-fw {
  width: 1.28571429em;
  text-align: center;
}
.icon.icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.icon.icon-ul > li {
  position: relative;
}
.icon.icon-li {
  position: absolute;
  top: 0.14285714em;
  left: -2.14285714em;
  width: 2.14285714em;
  text-align: center;
}
.icon.icon-li.icon-lg {
  left: -1.85714286em;
}
.icon.icon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #e4eaec;
  border-radius: .1em;
}
.icon.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  -o-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}
.icon.icon-spin-reverse {
  -webkit-animation: icon-spin-reverse 2s infinite linear;
  -o-animation: icon-spin-reverse 2s infinite linear;
  animation: icon-spin-reverse 2s infinite linear;
}
.icon.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
.icon.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.icon.icon-stack-1x,
.icon.icon-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.icon.icon-stack-1x {
  line-height: inherit;
}
.icon.icon-stack-2x {
  font-size: 2em;
}
.icon.icon-stack-inverse {
  color: #ffffff;
}
.icon-color {
  color: rgba(55, 71, 79, 0.4);
}
.icon-color:hover,
.icon-color:focus {
  color: rgba(55, 71, 79, 0.6);
}
.icon-color.active,
.icon-color:active {
  color: #37474f;
}
.icon-color-alt {
  color: rgba(55, 71, 79, 0.6);
}
.icon-color-alt:hover,
.icon-color-alt:focus {
  color: rgba(55, 71, 79, 0.8);
}
.icon-color-alt.active,
.icon-color-alt:active {
  color: #37474f;
}
:root-rotate-90,
:root-rotate-180,
:root-rotate-270,
:root-flip-horizontal,
:root-flip-vertical {
  filter: none;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
@keyframes icon-spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
.hamburger {
  vertical-align: middle;
  font-size: 20px;
}
.hamburger,
.hamburger:before,
.hamburger .hamburger-bar,
.hamburger:after {
  -webkit-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.hamburger:before,
.hamburger:after {
  content: "";
}
.hamburger:before,
.hamburger .hamburger-bar,
.hamburger:after {
  display: block;
  width: 1em;
  height: 0.1em;
  margin: 0;
  border-radius: 1px;
  background: #76838f;
}
.navbar-default .hamburger:before,
.navbar-default .hamburger .hamburger-bar,
.navbar-default .hamburger:after {
  background: #76838f;
}
.navbar-inverse .hamburger:before,
.navbar-inverse .hamburger .hamburger-bar,
.navbar-inverse .hamburger:after {
  background: #ffffff;
}
.hamburger .hamburger-bar {
  margin: 0.2em 0;
}
.hamburger-close:before {
  transform: rotate(45deg);
  transform-origin: 8%;
}
.hamburger-close .hamburger-bar {
  opacity: 0;
}
.hamburger-close:after {
  transform: rotate(-45deg);
  transform-origin: 8%;
}
.hamburger-close.hided:before,
.hamburger-close.collapsed:before {
  transform: rotate(0);
}
.hamburger-close.hided .hamburger-bar,
.hamburger-close.collapsed .hamburger-bar {
  opacity: 1;
}
.hamburger-close.hided:after,
.hamburger-close.collapsed:after {
  transform: rotate(0);
}
.hamburger-arrow-left.collapsed {
  transform: rotate(180deg);
}
.hamburger-arrow-left.collapsed:before {
  width: .6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}
.hamburger-arrow-left.collapsed .hamburger-bar {
  border-radius: 0.2em;
}
.hamburger-arrow-left.collapsed:after {
  width: .6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}
.counter {
  text-align: center;
}
.counter > .counter-number,
.counter .counter-number-group {
  color: #37474f;
  font-size: 20px;
}
.counter-label {
  display: block;
}
.counter-icon {
  font-size: 20px;
}
.counter-lg > .counter-number,
.counter-lg .counter-number-group {
  font-size: 40px;
}
.counter-lg .counter-icon {
  font-size: 40px;
}
.counter-md > .counter-number,
.counter-md .counter-number-group {
  font-size: 30px;
}
.counter-md .counter-icon {
  font-size: 30px;
}
.counter-sm > .counter-number,
.counter-sm .counter-number-group {
  font-size: 14px;
}
.counter-sm .counter-icon {
  font-size: 14px;
}
.counter-sm .counter-number-related + .counter-number,
.counter-sm .counter-number + .counter-number-related {
  margin-left: 0;
}
.counter-inverse {
  color: #ffffff;
}
.counter-inverse > .counter-number,
.counter-inverse .counter-number-group {
  color: #ffffff;
}
.counter-inverse .counter-icon {
  color: #ffffff;
}
.widget {
  background-color: #ffffff;
  margin-bottom: 30px;
  position: relative;
}
.widget .cover {
  width: 100%;
}
[class*="blocks-"] > li > .widget {
  margin-bottom: 0;
}
.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.widget {
  border-radius: 3px;
}
.widget .widget-header {
  border-radius: 3px 3px 0 0;
}
.widget .widget-header:last-child {
  border-radius: inherit;
}
.widget .widget-body:last-child {
  border-radius: 0 0 3px 3px;
}
.widget .widget-body:last-child .widget-body-footer {
  border-radius: 0 0 3px 3px;
}
.widget .widget-footer {
  border-radius: 0 0 3px 3px;
}
.widget .widget-footer:first-child {
  border-radiu: inherit;
}
.widget-body {
  position: relative;
  padding: 30px 25px;
}
.widget-body-footer {
  margin-top: 30px;
}
.widget-body-footer:before,
.widget-body-footer:after {
  content: " ";
  display: table;
}
.widget-body-footer:after {
  clear: both;
}
.widget-content ul {
  margin: 0;
  padding: 0;
}
.widget-content li {
  list-style: none;
}
.widget-title {
  margin-top: 0;
  color: #37474f;
  text-transform: capitalize;
}
div.widget-title {
  font-size: 22px;
}
.overlay-panel .widget-title {
  color: #ffffff;
}
.widget > .widget-title {
  padding: 12px 20px;
}
.widget-metas {
  color: #a3afb7;
  font-size: 12px;
}
.widget-metas.type-link > a {
  position: relative;
  display: inline-block;
  padding: 3px 5px;
  color: #a3afb7;
}
.widget-metas.type-link > a:first-child {
  padding-left: 0;
}
.widget-metas.type-link > a:hover {
  color: #ccd5db;
}
.widget-metas.type-link > a + a:before {
  position: absolute;
  top: 10px;
  left: -2px;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #a3afb7;
  border-radius: 50%;
}
.overlay-background .widget-time {
  opacity: 0.8;
  color: #ffffff;
}
.widget-category {
  font-size: 16px;
}
.widget-actions {
  margin-top: 10px;
  text-align: right;
}
.widget-actions a {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  color: #a3afb7;
}
.widget-actions a.icon,
.widget-actions a .icon {
  text-decoration: none;
}
.widget-actions a.icon + span,
.widget-actions a .icon + span {
  margin-left: 2px;
}
.widget-actions a.active,
.widget-actions a:hover,
.widget-actions a:focus {
  text-decoration: none;
  color: #ccd5db;
}
.widget-actions a:last-child {
  margin-right: 0;
}
.widget-actions-sidebar {
  width: 60px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.widget-actions-sidebar a {
  display: inline-block;
  width: 100%;
  height: 60px;
  text-align: center;
  border-right: 1px solid #e4eaec;
  margin-right: 0;
}
.widget-actions-sidebar a:before {
  display: inline-block;
  content: '';
  height: 100%;
  vertical-align: middle;
}
.widget-actions-sidebar a + a {
  border-top: 1px solid #e4eaec;
}
.widget-actions-sidebar + .widget-content {
  margin-left: 80px;
}
.widget-watermark {
  position: absolute;
  opacity: 0.1;
  right: 0;
  bottom: 0;
  line-height: 1;
}
.widget-watermark.darker {
  color: black;
}
.widget-watermark.lighter {
  color: white;
}
.widget-divider:after {
  width: 20px;
  height: 2px;
  content: "";
  display: block;
  margin: 15px auto;
  background-color: #ffffff;
}
.widget-left {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 0;
  left: 0;
}
.widget-left + .widget-body {
  margin-left: 40%;
  width: 60%;
}
@media (max-width: 767px) {
  .widget-left {
    position: relative;
    width: 100%;
    height: 320px;
  }
  .widget-left + .widget-body {
    margin-left: 0;
    width: 100%;
  }
}
.panel-group .panel {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-group .panel-title {
  position: relative;
  padding: 15px 30px;
  font-size: 14px;
}
.panel-group .panel-title:before,
.panel-group .panel-title:after {
  position: absolute;
  top: 15px;
  right: 30px;
  font-family: "Web Icons";
  -webkit-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}
.panel-group .panel-title:before {
  content: "\f197";
}
.panel-group .panel-title:after {
  content: "\f199";
}
.panel-group .panel-title[aria-expanded="false"]:before {
  opacity: .4;
}
.panel-group .panel-title[aria-expanded="false"]:after {
  opacity: 0;
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.panel-group .panel-title[aria-expanded="true"]:before {
  opacity: 0;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.panel-group .panel-title[aria-expanded="true"]:after {
  opacity: 1;
}
.panel-group .panel-title:hover,
.panel-group .panel-title:focus {
  color: #76838f;
  text-decoration: none;
}
.panel-group .panel-title:focus {
  outline: none;
}
.panel-group .panel-heading + .panel-collapse {
  margin: 0;
}
.panel-group .panel-collapse .panel-body {
  padding: 15px 30px;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: transparent;
}
.panel-group .panel + .panel {
  margin-top: 10px;
}
.panel-group-continuous .panel {
  border-radius: 0;
}
.panel-group-continuous .panel:first-child {
  border-radius: 4px 4px 0 0;
}
.panel-group-continuous .panel:last-child {
  border-radius: 0 0 4px 4px;
}
.panel-group-continuous .panel + .panel {
  margin-top: 0;
  border-top: 1px solid #e4eaec;
}
.panel-group-simple .panel {
  background: transparent;
  box-shadow: none;
}
.panel-group-simple .panel-title {
  padding-left: 0;
  padding-bottom: 10px;
}
.panel-group-simple .panel-title:before,
.panel-group-simple .panel-title:after {
  right: 5px;
}
.panel-group-simple .panel-collapse .panel-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}
.panel-group-simple .panel + .panel {
  margin-top: 0;
}
.cover {
  overflow: hidden;
}
.cover-background {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.cover-image {
  width: 100%;
}
.cover-quote {
  position: relative;
  margin-bottom: 0;
  padding-left: 35px;
  border-left: none;
}
.cover-quote:before,
.cover-quote:after {
  position: absolute;
  top: -20px;
  font-size: 4em;
}
.cover-quote:before {
  left: 0;
  content: open-quote;
}
.cover-quote:after {
  right: 0;
  content: close-quote;
  visibility: hidden;
}
.cover-quote.blockquote-reverse {
  padding-right: 35px;
  padding-left: 20px;
  border-right: none;
}
.cover-quote.blockquote-reverse:before {
  right: 0;
  left: auto;
  content: close-quote;
}
.cover-gallery .carousel-inner img {
  width: 100%;
}
.cover-iframe {
  width: 100%;
  border: 0 none;
}
/* ========================================================================
   Component: Overlay
 ========================================================================== */
.overlay {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.overlay > :first-child,
.overlay .overlay-figure {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}
/* Sub-object `overlay-panel`
 ========================================================================== */
.overlay-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  color: #ffffff;
}
.overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline;
}
.overlay-panel > :last-child {
  margin-bottom: 0;
}
.overlay-panel h1,
.overlay-panel h2,
.overlay-panel h3,
.overlay-panel h4,
.overlay-panel h5,
.overlay-panel h6 {
  color: inherit;
}
/* Sub-object `overlay-hover`
 ========================================================================== */
.overlay-hover:not(:hover) .overlay-panel:not(.overlay-background-fixed) {
  opacity: 0;
}
/* Modifier `overlay-background`
 ========================================================================== */
.overlay-background {
  background: rgba(0, 0, 0, 0.5);
}
/* Modifier `overlay-image`
 ========================================================================== */
.overlay-image {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
/* Modifier `overlay-shade`
 ========================================================================== */
.overlay-shade {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.87) 90%, #fff 100%) repeat scroll 0 0;
}
/* Position modifiers
 ========================================================================== */
.overlay-top {
  bottom: auto;
}
.overlay-bottom {
  top: auto;
}
.overlay-left {
  right: auto;
}
.overlay-right {
  left: auto;
}
/* Sub-object `overlay-icon`
 ========================================================================== */
.overlay-icon {
  font-size: 0;
  text-align: center;
}
.overlay-icon:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.overlay-icon .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 32px;
  line-height: 1;
  color: #ffffff;
  text-decoration: none;
}
/* Sub-object `overlay-anchor`
 ========================================================================== */
.overlay-anchor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/* Transitions
 ========================================================================== */
.overlay-fade,
.overlay-scale,
.overlay-spin,
.overlay-grayscale,
.overlay-blur,
[class*='overlay-slide'] {
  transition-timing-function: ease-out;
  transition-duration: .3s;
  transition-property: opacity transform filter;
}
.overlay-fade {
  opacity: 0.7;
}
.overlay-hover:hover .overlay-fade {
  opacity: 1;
}
.overlay-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.overlay-hover:hover .overlay-scale {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.overlay-hover:hover .overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}
.overlay-grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.overlay-hover:hover .overlay-grayscale {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
[class*='overlay-slide'] {
  opacity: 0;
}
/* Top */
.overlay-slide-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
/* Bottom */
.overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
/* Left */
.overlay-slide-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* Right */
.overlay-slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
/* Hover */
.overlay-hover:hover [class*='overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}
.comments {
  padding: 0;
  margin: 0;
}
.comments .comment {
  border: none;
  border-bottom: 1px solid #e4eaec;
}
.comments .comment .comment:first-child {
  border-top: 1px solid #e4eaec;
}
.comments .comment .comment:last-child {
  border-bottom: none;
}
.comment {
  padding: 20px 0;
  margin: 0;
}
.comment .comment {
  margin-top: 20px;
  padding-bottom: 20px;
}
.comment .comment:last-child {
  padding-bottom: 0;
}
.comment-author,
.comment-author:hover,
.comment-author:focus {
  color: #37474f;
}
.comment-meta {
  margin-left: 5px;
  display: inline-block;
  font-size: 12px;
  color: #a3afb7;
}
.comment-content {
  margin-top: 5px;
}
.comment-content p {
  margin-bottom: 10px;
}
.comment-actions {
  margin-top: 10px;
  text-align: right;
}
.comment-actions a {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.comment-actions a.icon {
  text-decoration: none;
}
.comment-actions a:last-child {
  margin-right: 0;
}
.comment-reply {
  margin: 22px 0 10px;
}
.comment-reply .form-group:last-child {
  margin-bottom: 0;
}
.chat-box {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
}
.chats {
  padding: 30px 15px;
}
.chat-avatar {
  float: right;
}
.chat-avatar .avatar {
  width: 30px;
}
.chat-body {
  display: block;
  margin: 10px 30px 0 0;
  overflow: hidden;
}
.chat-body:first-child {
  margin-top: 0;
}
.chat-content {
  position: relative;
  display: block;
  float: right;
  padding: 8px 15px;
  margin: 0 20px 10px 0;
  clear: both;
  color: #ffffff;
  background-color: #62a8ea;
  border-radius: 4px;
}
.chat-content:before {
  position: absolute;
  top: 10px;
  right: -10px;
  width: 0;
  height: 0;
  content: '';
  border: 5px solid transparent;
  border-left-color: #62a8ea;
}
.chat-content > p:last-child {
  margin-bottom: 0;
}
.chat-content + .chat-content:before {
  border-color: transparent;
}
.chat-time {
  display: block;
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.6);
}
.chat-left .chat-avatar {
  float: left;
}
.chat-left .chat-body {
  margin-right: 0;
  margin-left: 30px;
}
.chat-left .chat-content {
  float: left;
  margin: 0 0 10px 20px;
  color: #76838f;
  background-color: #dfe9ef;
}
.chat-left .chat-content:before {
  right: auto;
  left: -10px;
  border-right-color: #dfe9ef;
  border-left-color: transparent;
}
.chat-left .chat-content + .chat-content:before {
  border-color: transparent;
}
.chat-left .chat-time {
  color: #a3afb7;
}
.steps {
  margin-bottom: 22px;
}
.steps.row {
  margin-left: 0;
  margin-right: 0;
  display: block;
}
.step {
  position: relative;
  padding: 12px 20px;
  margin: 0;
  color: #a3afb7;
  font-size: inherit;
  vertical-align: top;
  background-color: #f3f7f9;
  border-radius: 0;
}
.step-icon {
  float: left;
  font-size: 20px;
  margin-right: 0.5em;
}
.step-number {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  background: #e4eaec;
  border-radius: 50%;
}
.step-number ~ .step-desc {
  min-height: 40px;
  margin-left: 50px;
}
.step-title {
  font-size: 20px;
  color: #526069;
  margin-bottom: 0;
}
.step-desc {
  text-align: left;
}
.step-desc p {
  margin-bottom: 0;
}
.steps-vertical .step {
  display: block;
  padding: 18px 20px;
}
.steps-vertical .step[class*="col-"] {
  width: 100%;
  float: none;
}
.step.current {
  color: #ffffff;
  background-color: #62a8ea;
}
.step.current .step-title {
  color: #ffffff;
}
.step.current .step-number {
  color: #62a8ea;
  background-color: #ffffff;
}
.step.disabled {
  color: #ccd5db;
  pointer-events: none;
  cursor: auto;
}
.step.disabled .step-title {
  color: #ccd5db;
}
.step.disabled .step-number {
  background-color: #ccd5db;
}
.step.error {
  color: #ffffff;
  background-color: #f96868;
}
.step.error .step-title {
  color: #ffffff;
}
.step.error .step-number {
  color: #f96868;
  background-color: #ffffff;
}
.step.done {
  color: #ffffff;
  background-color: #46be8a;
}
.step.done .step-title {
  color: #ffffff;
}
.step.done .step-number {
  color: #46be8a;
  background-color: #ffffff;
}
.steps-lg .step {
  padding: 20px 20px;
  font-size: 16px;
}
.steps-lg .step-icon {
  font-size: 22px;
}
.steps-lg .step-title {
  font-size: 22px;
}
.steps-lg .step-number {
  width: 46px;
  height: 46px;
  font-size: 28px;
  line-height: 46px;
}
.steps-lg .step-number ~ .step-desc {
  min-height: 46px;
  margin-left: 56px;
}
.steps-sm .step {
  font-size: 12px;
}
.steps-sm .step-icon {
  font-size: 18px;
}
.steps-sm .step-title {
  font-size: 18px;
}
.steps-sm .step-number {
  width: 30px;
  height: 30px;
  font-size: 24px;
  line-height: 30px;
}
.steps-sm .step-number ~ .step-desc {
  min-height: 30px;
  margin-left: 40px;
}
.steps-xs .step {
  font-size: 10px;
}
.steps-xs .step-icon {
  font-size: 16px;
}
.steps-xs .step-title {
  font-size: 16px;
}
.steps-xs .step-number {
  width: 24px;
  height: 24px;
  font-size: 20px;
  line-height: 24px;
}
.steps-xs .step-number ~ .step-desc {
  min-height: 24px;
  margin-left: 34px;
}
.pearls {
  margin-bottom: 22px;
}
.pearls.row {
  display: block;
}
.pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.pearl:before,
.pearl:after {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #f3f7f9;
}
.pearl:before {
  left: 0;
}
.pearl:after {
  right: 0;
}
.pearl:first-child:before,
.pearl:last-child:after {
  display: none !important;
}
.pearl-number,
.pearl-icon {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  background: #ccd5db;
  border-radius: 50%;
  border: 2px solid #ccd5db;
}
.pearl-number {
  font-size: 18px;
}
.pearl-icon {
  font-size: 18px;
}
.pearl-title {
  margin-top: 0.5em;
  display: block;
  font-size: 16px;
  color: #526069;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  /* for IE */
  white-space: nowrap;
}
.pearl.current:before,
.pearl.current:after {
  background-color: #62a8ea;
}
.pearl.current .pearl-number,
.pearl.current .pearl-icon {
  color: #62a8ea;
  background-color: #ffffff;
  border-color: #62a8ea;
  transform: scale(1.3);
}
.pearl.disabled {
  pointer-events: none;
  cursor: auto;
}
.pearl.disabled:before,
.pearl.disabled:after {
  background-color: #f3f7f9;
}
.pearl.disabled .pearl-number,
.pearl.disabled .pearl-icon {
  color: #ffffff;
  background-color: #ccd5db;
  border-color: #ccd5db;
}
.pearl.error:before {
  background-color: #62a8ea;
}
.pearl.error:after {
  background-color: #f3f7f9;
}
.pearl.error .pearl-number,
.pearl.error .pearl-icon {
  color: #f96868;
  background-color: #ffffff;
  border-color: #f96868;
}
.pearl.done:before,
.pearl.done:after {
  background-color: #62a8ea;
}
.pearl.done .pearl-number,
.pearl.done .pearl-icon {
  color: #ffffff;
  background-color: #62a8ea;
  border-color: #62a8ea;
}
.pearls-lg .pearl:before,
.pearls-lg .pearl:after {
  top: 20px;
}
.pearls-lg .pearl-title {
  font-size: 18px;
}
.pearls-lg .pearl-number,
.pearls-lg .pearl-icon {
  width: 40px;
  height: 40px;
  line-height: 36px;
}
.pearls-lg .pearl-icon {
  font-size: 20px;
}
.pearls-lg .pearl-number {
  font-size: 20px;
}
.pearls-sm .pearl:before,
.pearls-sm .pearl:after {
  top: 16px;
}
.pearls-sm .pearl-title {
  font-size: 14px;
}
.pearls-sm .pearl-number,
.pearls-sm .pearl-icon {
  width: 32px;
  height: 32px;
  line-height: 28px;
}
.pearls-sm .pearl-number {
  font-size: 16px;
}
.pearls-sm .pearl-icon {
  font-size: 14px;
}
.pearls-xs .pearl:before,
.pearls-xs .pearl:after {
  top: 12px;
  height: 2px;
}
.pearls-xs .pearl-title {
  font-size: 12px;
}
.pearls-xs .pearl-number,
.pearls-xs .pearl-icon {
  width: 24px;
  height: 24px;
  line-height: 20px;
}
.pearls-xs .pearl-number {
  font-size: 12px;
}
.pearls-xs .pearl-icon {
  font-size: 12px;
}
.timeline {
  position: relative;
  padding: 0;
  margin-bottom: 22px;
  list-style: none;
  background: transparent;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  margin-left: -1px;
  content: "";
  background-color: #e4eaec;
}
.timeline:after {
  display: table;
  content: "";
}
.timeline:after {
  clear: both;
}
.timeline > li {
  position: relative;
  display: block;
  float: left;
  width: 50%;
  padding-right: 40px;
  margin-bottom: 60px;
  clear: left;
}
.timeline > li.timeline-period {
  position: relative;
  z-index: 6;
  display: block;
  float: none;
  width: 200px;
  padding: 25px 10px;
  margin: 20px auto 30px;
  clear: both;
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  background: #f1f4f5;
}
.timeline > li.timeline-reverse {
  float: right;
  padding-right: 0;
  padding-left: 40px;
  clear: right;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline:not(.timeline-single) > li:not(.timeline-period):first-child + .timeline-item,
.timeline:not(.timeline-single) > li.timeline-period + .timeline-item + .timeline-item {
  margin-top: 90px;
}
.timeline-content {
  float: left;
  width: 100%;
  overflow: hidden;
  clear: left;
}
.timeline-reverse .timeline-content {
  float: right;
  clear: right;
}
.timeline-content > .widget {
  margin: 0;
}
.timeline-dot {
  position: absolute;
  right: 0;
  z-index: 11;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: #62a8ea;
  border-radius: 50%;
  top: 7.5px;
}
.timeline-reverse .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
}
.timeline .timeline-dot {
  width: 14px;
  height: 14px;
  margin-right: -7px;
  line-height: 14px;
}
.timeline .timeline-reverse .timeline-dot {
  margin-left: -7px;
}
.timeline.timeline-single {
  margin-left: 7px;
}
.timeline.timeline-single .timeline-dot {
  margin-left: -7px;
}
@media screen and (max-width: 767px) {
  .timeline {
    margin-left: 7px;
  }
  .timeline .timeline-dot {
    margin-left: -7px;
  }
}
.timeline-info {
  float: right;
  padding: 0 20px;
  margin-bottom: 22px;
  line-height: 28px;
  text-align: center;
  background: #e4eaec;
  border: 1px solid #e4eaec;
  border-radius: 20px;
}
.timeline-reverse .timeline-info {
  float: left;
}
.timeline-footer {
  position: absolute;
  right: 0;
  bottom: -30px;
  margin-right: 55px;
}
.timeline-footer .icon {
  margin-right: .3em;
}
.timeline-reverse .timeline-footer {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: 55px;
}
.timeline-reverse + .timeline-reverse {
  margin-top: 0;
}
@media screen and (max-width: 767px) {
  .timeline:before {
    left: 0;
  }
  .timeline > li,
  .timeline li.timeline-reverse {
    float: none;
    width: 100%;
    padding-right: 0;
    padding-left: 40px;
    margin-top: 0;
    margin-bottom: 60px;
  }
  .timeline-content {
    float: none;
  }
  .timeline-dot {
    right: auto;
    left: 0;
    margin-right: 0;
    margin-left: -7px;
  }
  .timeline-info {
    display: inline-block;
    float: none;
  }
  .timeline-footer {
    right: auto;
    bottom: -26px;
    left: 0;
    margin-right: 0;
    margin-left: 40px;
  }
}
.timeline-single:before {
  left: 0;
}
.timeline-single > li {
  float: none;
  width: 100%;
  padding-right: 0;
  padding-left: 40px;
  margin-bottom: 60px;
}
.timeline-single .timeline-content {
  float: none;
}
.timeline-single .timeline-dot {
  right: auto;
  left: 0;
  margin-right: 0;
  margin-left: -7px;
}
.timeline-single .timeline-info {
  float: left;
}
.timeline-single .timeline-footer {
  right: auto;
  bottom: -26px;
  left: 0;
  margin-right: 0;
  margin-left: 40px;
}
.timeline-icon .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}
.timeline-icon .timeline-reverse .timeline-dot {
  margin-left: -20px;
}
.timeline-icon.timeline-single {
  margin-left: 20px;
}
.timeline-icon.timeline-single .timeline-dot {
  margin-left: -20px;
}
@media screen and (max-width: 767px) {
  .timeline-icon {
    margin-left: 20px;
  }
  .timeline-icon .timeline-dot {
    margin-left: -20px;
  }
}
.timeline-icon .timeline-dot {
  top: -5.5px;
}
.timeline-avatar .timeline-dot {
  width: 40px;
  height: 40px;
  margin-right: -20px;
  line-height: 40px;
}
.timeline-avatar .timeline-reverse .timeline-dot {
  margin-left: -20px;
}
.timeline-avatar.timeline-single {
  margin-left: 20px;
}
.timeline-avatar.timeline-single .timeline-dot {
  margin-left: -20px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar {
    margin-left: 20px;
  }
  .timeline-avatar .timeline-dot {
    margin-left: -20px;
  }
}
.timeline-avatar-sm .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}
.timeline-avatar-sm .timeline-reverse .timeline-dot {
  margin-left: -15px;
}
.timeline-avatar-sm.timeline-single {
  margin-left: 15px;
}
.timeline-avatar-sm.timeline-single .timeline-dot {
  margin-left: -15px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar-sm {
    margin-left: 15px;
  }
  .timeline-avatar-sm .timeline-dot {
    margin-left: -15px;
  }
}
.timeline-avatar-lg .timeline-dot {
  width: 50px;
  height: 50px;
  margin-right: -25px;
  line-height: 50px;
}
.timeline-avatar-lg .timeline-reverse .timeline-dot {
  margin-left: -25px;
}
.timeline-avatar-lg.timeline-single {
  margin-left: 25px;
}
.timeline-avatar-lg.timeline-single .timeline-dot {
  margin-left: -25px;
}
@media screen and (max-width: 767px) {
  .timeline-avatar-lg {
    margin-left: 25px;
  }
  .timeline-avatar-lg .timeline-dot {
    margin-left: -25px;
  }
}
.timeline-simple .timeline-dot {
  top: 0;
  margin-top: 10px;
}
.timeline-feed .timeline-dot {
  width: 30px;
  height: 30px;
  margin-right: -15px;
  line-height: 30px;
}
.timeline-feed .timeline-reverse .timeline-dot {
  margin-left: -15px;
}
.timeline-feed.timeline-single {
  margin-left: 15px;
}
.timeline-feed.timeline-single .timeline-dot {
  margin-left: -15px;
}
@media screen and (max-width: 767px) {
  .timeline-feed {
    margin-left: 15px;
  }
  .timeline-feed .timeline-dot {
    margin-left: -15px;
  }
}
.timeline-feed.timeline-simple .timeline-dot {
  margin-top: 5px;
}
.timeline-feed > li {
  padding-right: 30px;
  margin-bottom: 22px;
}
.timeline-feed > li.timeline-reverse {
  padding-left: 30px;
}
.timeline-feed.timeline-single > li {
  padding-left: 30px;
}
@media screen and (max-width: 767px) {
  .timeline-feed > li {
    padding-right: 30px;
    margin-bottom: 22px;
  }
}
@media screen and (max-width: 767px) {
}
.testimonial {
  margin: 3px 3px 22px;
}
.testimonial-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.testimonial-item {
  float: left;
  padding: 0 15px 30px;
  margin: 0;
}
.testimonial-content {
  position: relative;
  padding: 15px 20px;
  margin-top: 10px;
  margin-bottom: 25px;
  background-color: #f3f7f9;
  border-radius: 3px;
}
.testimonial-content:before {
  position: absolute;
  bottom: -7px;
  left: 33px;
  display: block;
  width: 14px;
  height: 14px;
  content: '';
  background-color: #f3f7f9;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.testimonial-content > p:last-child {
  margin-bottom: 0;
}
.testimonial-image {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}
.testimonial-author {
  display: block;
  margin-left: 75px;
  font-size: 18px;
}
.testimonial-company {
  display: block;
  margin-left: 75px;
  font-size: 12px;
  opacity: .8;
}
.testimonial-control a {
  color: #ccd5db;
}
.testimonial-control a:hover {
  color: #89bceb;
  text-decoration: none;
}
.testimonial-reverse .testimonial-content:before {
  right: 33px;
  left: auto;
}
.testimonial-reverse .testimonial-image {
  float: right;
  margin-right: 20px;
  margin-left: 0;
}
.testimonial-reverse .testimonial-author,
.testimonial-reverse .testimonial-company {
  margin-left: 0;
  margin-right: 75px;
  text-align: right;
}
.testimonial-top .testimonial-item {
  padding: 30px 15px 0;
}
.testimonial-top .testimonial-content {
  margin-top: 30px;
  margin-bottom: 10px;
}
.testimonial-top .testimonial-content:before {
  top: -7px;
  bottom: auto;
}
.testimonial.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.testimonial.carousel .testimonial-item {
  position: relative;
  display: none;
  width: 100%;
  padding: 10px;
  -webkit-transition: left 0.6s ease-in-out 0s;
  -o-transition: left 0.6s ease-in-out 0s;
  transition: left 0.6s ease-in-out 0s;
}
.testimonial.carousel .testimonial-item.active,
.testimonial.carousel .testimonial-item.next,
.testimonial.carousel .testimonial-item.prev {
  display: block;
}
.testimonial.carousel .testimonial-item.next,
.testimonial.carousel .testimonial-item.prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.testimonial.carousel .testimonial-item.next {
  left: 100%;
}
.testimonial.carousel .testimonial-item.prev {
  left: -100%;
}
.testimonial.carousel .testimonial-item.next.left,
.testimonial.carousel .testimonial-item.prev.right {
  left: 0;
}
.testimonial.carousel .testimonial-item.active {
  left: 0;
}
.testimonial.carousel .testimonial-item.active.left {
  left: -100%;
}
.testimonial.carousel .testimonial-item.active.right {
  left: 100%;
}
.testimonial.carousel .testimonial-content {
  padding: 10px;
}
.testimonial.carousel .testimonial-control {
  position: absolute;
  right: 10px;
  bottom: 20px;
}
.testimonial.carousel .testimonial-control > * {
  margin-left: 10px;
}
.testimonial.carousel.testimonial-reverse .testimonial-control {
  right: auto;
  left: 10px;
}
.testimonial.carousel.testimonial-reverse .testimonial-control > * {
  margin-right: 10px;
  margin-left: 0;
}
.testimonial.carousel.testimonial-top .testimonial-control {
  top: 20px;
  bottom: auto;
}
.pricing-list {
  margin-bottom: 22px;
  text-align: center;
  border: 1px solid #e4eaec;
  border-radius: 3px;
}
.pricing-list [class^="bg-"],
.pricing-list [class*="bg-"],
.pricing-list [class^="bg-"] *,
.pricing-list [class*="bg-"] * {
  color: #ffffff;
}
.pricing-list .pricing-header {
  border-bottom: 1px solid #e4eaec;
  border-radius: 3px 3px 0 0;
}
.pricing-list .pricing-title {
  padding: 15px 30px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 3px 3px 0 0;
}
.pricing-list .pricing-price {
  padding: 20px 30px;
  margin: 0;
  font-size: 54px;
  font-weight: 500;
  color: #37474f;
}
.pricing-list .pricing-period {
  font-size: 14px;
  font-weight: 300;
}
.pricing-list .pricing-features {
  padding: 0 18px;
  margin: 0;
}
.pricing-list .pricing-features li {
  display: block;
  padding: 15px;
  list-style: none;
  border-top: 1px dashed #e4eaec;
}
.pricing-list .pricing-features li:first-child {
  border-top: none;
}
.pricing-list .pricing-footer {
  padding: 30px;
  border-radius: 0 0 3px 3px;
}
.pricing-table {
  padding-top: 30px;
  text-align: center;
}
.pricing-table:before,
.pricing-table:after {
  content: " ";
  display: table;
}
.pricing-table:after {
  clear: both;
}
.pricing-table [class*="pricing-column"] {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  background-color: #f3f7f9;
  border: 1px solid #e4eaec;
  border-right: none;
}
.pricing-table [class*="pricing-column"]:last-child {
  border-right: 1px solid #e4eaec;
}
.pricing-table [class*="pricing-column"].featured {
  position: relative;
  margin-right: -1px;
  background-color: #ffffff;
  border-right: 1px solid #e4eaec;
}
.pricing-table .pricing-header {
  padding-bottom: 24px;
  margin: 30px 30px 25px;
  border-bottom: 1px solid #e4eaec;
}
.pricing-table .pricing-price {
  font-size: 48px;
}
.pricing-table .pricing-currency {
  display: inline-block;
  margin-top: 10px;
  margin-right: -10px;
  font-size: 20px;
  vertical-align: top;
}
.pricing-table .pricing-period {
  font-size: 16px;
}
.pricing-table .pricing-title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.pricing-table .pricing-features {
  padding: 0;
  margin: 0;
}
.pricing-table .pricing-features li {
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.pricing-table .pricing-footer {
  padding: 20px 0;
  margin: 25px 30px 30px;
}
@media screen and (min-width: 768px) {
  .pricing-table .pricing-column-three {
    width: 33.33%;
  }
  .pricing-table .pricing-column-three.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
  .pricing-table .pricing-column-four {
    width: 50%;
  }
  .pricing-table .pricing-column-five {
    width: 50%;
  }
}
@media screen and (min-width: 1200px) {
  .pricing-table .pricing-column-four {
    width: 25%;
  }
  .pricing-table .pricing-column-five {
    width: 20%;
  }
  .pricing-table .pricing-column-four.featured,
  .pricing-table .pricing-column-five.featured {
    top: -30px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: -30px;
  }
}
.rating {
  display: inline-block;
  margin: 0 .5rem 0 0;
  font-size: 0;
  vertical-align: middle;
}
.rating:before {
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: '';
}
.rating.hover .icon.active {
  opacity: .5;
}
.rating .icon {
  width: 1em;
  height: auto;
  padding: 0;
  margin: 0 10px 0 0;
  font-size: 14px;
  color: #ccd5db;
  vertical-align: middle;
  cursor: pointer;
}
.rating .icon:before {
  transition: color .3s ease,
      opacity .3s ease;
}
.rating .icon.active {
  color: #f2a654 !important;
}
.rating .icon.active.hover {
  color: #f2a654 !important;
  opacity: 1;
}
.rating .icon.hover {
  color: #f2a654 !important;
  opacity: 1;
}
.rating .icon:last-child {
  margin-right: 0;
}
.rating-disabled .icon {
  cursor: default;
}
.rating-sm .icon {
  font-size: 12px;
}
.rating-lg .icon {
  font-size: 18px;
}
.ribbon {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 150px;
  height: 150px;
  text-align: center;
  background-color: transparent;
}
.ribbon-inner {
  position: absolute;
  top: 16px;
  left: 0;
  display: inline-block;
  height: 30px;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  color: #ffffff;
  white-space: nowrap;
  background-color: #526069;
}
.ribbon-inner .icon {
  font-size: 16px;
}
.ribbon-lg .ribbon-inner {
  height: 38px;
  font-size: 18px;
  line-height: 38px;
}
.ribbon-sm .ribbon-inner {
  height: 26px;
  font-size: 12px;
  line-height: 26px;
}
.ribbon-xs .ribbon-inner {
  height: 22px;
  font-size: 12px;
  line-height: 22px;
}
.ribbon-vertical .ribbon-inner {
  top: 0;
  left: 16px;
  width: 30px;
  height: 60px;
  padding: 15px 0;
}
.ribbon-vertical.ribbon-xs .ribbon-inner {
  width: 22px;
  height: 50px;
}
.ribbon-vertical.ribbon-sm .ribbon-inner {
  width: 26px;
  height: 55px;
}
.ribbon-vertical.ribbon-lg .ribbon-inner {
  width: 38px;
  height: 70px;
}
.ribbon-reverse {
  right: -3px;
  left: auto;
}
.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}
.ribbon-reverse.ribbon-vertical .ribbon-inner {
  right: 16px;
}
.ribbon-bookmark .ribbon-inner {
  box-shadow: none;
}
.ribbon-bookmark .ribbon-inner:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border: 15px solid #526069;
  border-right: 10px solid transparent;
}
.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  top: 100%;
  left: 0;
  margin-top: -15px;
  border-right: 15px solid #526069;
  border-bottom: 10px solid transparent;
}
.ribbon-bookmark.ribbon-vertical.ribbon-xs .ribbon-inner:before {
  margin-top: -11px;
}
.ribbon-bookmark.ribbon-vertical.ribbon-sm .ribbon-inner:before {
  margin-top: -13px;
}
.ribbon-bookmark.ribbon-vertical.ribbon-lg .ribbon-inner:before {
  margin-top: -19px;
}
.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #526069;
  border-left: 10px solid transparent;
}
.ribbon-bookmark.ribbon-reverse.ribbon-vertical .ribbon-inner:before {
  right: auto;
  left: 0;
  border-right-color: #526069;
  border-bottom-color: transparent;
  border-left: 15px solid #526069;
}
.ribbon-bookmark.ribbon-xs .ribbon-inner:before {
  border-width: 11px;
}
.ribbon-bookmark.ribbon-sm .ribbon-inner:before {
  border-width: 13px;
}
.ribbon-bookmark.ribbon-lg .ribbon-inner:before {
  border-width: 19px;
}
.ribbon-badge {
  top: -2px;
  left: -2px;
  overflow: hidden;
}
.ribbon-badge .ribbon-inner {
  left: -40px;
  width: 100%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ribbon-badge.ribbon-reverse {
  right: -2px;
  left: auto;
}
.ribbon-badge.ribbon-reverse .ribbon-inner {
  right: -40px;
  left: auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ribbon-badge.ribbon-bottom {
  top: auto;
  bottom: -2px;
}
.ribbon-badge.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ribbon-badge.ribbon-bottom.ribbon-reverse .ribbon-inner {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ribbon-corner {
  top: 0;
  left: 0;
  overflow: hidden;
}
.ribbon-corner .ribbon-inner {
  top: 0;
  left: 0;
  width: 40px;
  height: 35px;
  padding: 0;
  line-height: 35px;
  background-color: transparent;
}
.ribbon-corner .ribbon-inner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 30px solid transparent;
  border-top-color: #526069;
  border-left-color: #526069;
}
.ribbon-corner.ribbon-reverse {
  right: 0;
  left: auto;
}
.ribbon-corner.ribbon-reverse .ribbon-inner {
  right: 0;
  left: auto;
}
.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  right: 0;
  left: auto;
  border-right-color: #526069;
  border-left-color: transparent;
}
.ribbon-corner.ribbon-bottom {
  top: auto;
  bottom: 0;
}
.ribbon-corner.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 0;
}
.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  top: auto;
  bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #526069;
}
.ribbon-corner.ribbon-xs .ribbon-inner {
  width: 28px;
  height: 26px;
  line-height: 26px;
}
.ribbon-corner.ribbon-xs .ribbon-inner:before {
  border-width: 22px;
}
.ribbon-corner.ribbon-xs .ribbon-inner > .icon {
  font-size: 12px;
}
.ribbon-corner.ribbon-sm .ribbon-inner {
  width: 34px;
  height: 32px;
  line-height: 32px;
}
.ribbon-corner.ribbon-sm .ribbon-inner:before {
  border-width: 26px;
}
.ribbon-corner.ribbon-sm .ribbon-inner > .icon {
  font-size: 12px;
}
.ribbon-corner.ribbon-lg .ribbon-inner {
  width: 46px;
  height: 44px;
  line-height: 44px;
}
.ribbon-corner.ribbon-lg .ribbon-inner:before {
  border-width: 36px;
}
.ribbon-corner.ribbon-lg .ribbon-inner > .icon {
  font-size: 18px;
}
.ribbon-clip {
  left: -14px;
}
.ribbon-clip .ribbon-inner {
  padding-left: 23px;
  border-radius: 0 5px 5px 0;
}
.ribbon-clip .ribbon-inner:after {
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 0;
  height: 0;
  content: '';
  border: 7px solid transparent;
  border-top-color: #37474f;
  border-right-color: #37474f;
}
.ribbon-clip.ribbon-reverse {
  right: -14px;
  left: auto;
}
.ribbon-clip.ribbon-reverse .ribbon-inner {
  padding-right: 23px;
  padding-left: 15px;
  border-radius: 5px 0 0 5px;
}
.ribbon-clip.ribbon-reverse .ribbon-inner:after {
  right: 0;
  left: auto;
  border-right-color: transparent;
  border-left-color: #37474f;
}
.ribbon-clip.ribbon-bottom {
  top: auto;
  bottom: -3px;
}
.ribbon-clip.ribbon-bottom .ribbon-inner {
  top: auto;
  bottom: 16px;
}
.ribbon-clip.ribbon-bottom .ribbon-inner:after {
  top: -14px;
  bottom: auto;
  border-top-color: transparent;
  border-bottom-color: #37474f;
}
.ribbon-primary .ribbon-inner {
  background-color: #62a8ea;
}
.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
  border-color: #62a8ea;
  border-right-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #62a8ea;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #62a8ea;
  border-bottom-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #62a8ea;
  border-bottom-color: transparent;
  border-left-color: #62a8ea;
}
.ribbon-primary.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-primary.ribbon-corner .ribbon-inner:before {
  border-top-color: #62a8ea;
  border-left-color: #62a8ea;
}
.ribbon-primary.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #62a8ea;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #62a8ea;
}
.ribbon-primary .ribbon-inner:after {
  border-top-color: #4e97d9;
  border-right-color: #4e97d9;
}
.ribbon-primary.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #4e97d9;
}
.ribbon-primary.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #4e97d9;
}
.ribbon-success .ribbon-inner {
  background-color: #46be8a;
}
.ribbon-success.ribbon-bookmark .ribbon-inner:before {
  border-color: #46be8a;
  border-right-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #46be8a;
  border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #46be8a;
  border-bottom-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #46be8a;
  border-bottom-color: transparent;
  border-left-color: #46be8a;
}
.ribbon-success.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-success.ribbon-corner .ribbon-inner:before {
  border-top-color: #46be8a;
  border-left-color: #46be8a;
}
.ribbon-success.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #46be8a;
  border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #46be8a;
}
.ribbon-success .ribbon-inner:after {
  border-top-color: #36ab7a;
  border-right-color: #36ab7a;
}
.ribbon-success.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #36ab7a;
}
.ribbon-success.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #36ab7a;
}
.ribbon-info .ribbon-inner {
  background-color: #57c7d4;
}
.ribbon-info.ribbon-bookmark .ribbon-inner:before {
  border-color: #57c7d4;
  border-right-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #57c7d4;
  border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #57c7d4;
  border-bottom-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #57c7d4;
  border-bottom-color: transparent;
  border-left-color: #57c7d4;
}
.ribbon-info.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-info.ribbon-corner .ribbon-inner:before {
  border-top-color: #57c7d4;
  border-left-color: #57c7d4;
}
.ribbon-info.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #57c7d4;
  border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #57c7d4;
}
.ribbon-info .ribbon-inner:after {
  border-top-color: #47b8c6;
  border-right-color: #47b8c6;
}
.ribbon-info.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #47b8c6;
}
.ribbon-info.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #47b8c6;
}
.ribbon-warning .ribbon-inner {
  background-color: #f2a654;
}
.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
  border-color: #f2a654;
  border-right-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f2a654;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #f2a654;
  border-bottom-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f2a654;
  border-bottom-color: transparent;
  border-left-color: #f2a654;
}
.ribbon-warning.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-warning.ribbon-corner .ribbon-inner:before {
  border-top-color: #f2a654;
  border-left-color: #f2a654;
}
.ribbon-warning.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f2a654;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #f2a654;
}
.ribbon-warning .ribbon-inner:after {
  border-top-color: #ec9940;
  border-right-color: #ec9940;
}
.ribbon-warning.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #ec9940;
}
.ribbon-warning.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #ec9940;
}
.ribbon-danger .ribbon-inner {
  background-color: #f96868;
}
.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
  border-color: #f96868;
  border-right-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f96868;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
  border-right-color: #f96868;
  border-bottom-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f96868;
  border-bottom-color: transparent;
  border-left-color: #f96868;
}
.ribbon-danger.ribbon-corner .ribbon-inner {
  background-color: transparent;
}
.ribbon-danger.ribbon-corner .ribbon-inner:before {
  border-top-color: #f96868;
  border-left-color: #f96868;
}
.ribbon-danger.ribbon-corner.ribbon-reverse .ribbon-inner:before {
  border-right-color: #f96868;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom .ribbon-inner:before {
  border-top-color: transparent;
  border-bottom-color: #f96868;
}
.ribbon-danger .ribbon-inner:after {
  border-top-color: #e9595b;
  border-right-color: #e9595b;
}
.ribbon-danger.ribbon-reverse .ribbon-inner:after {
  border-right-color: transparent;
  border-left-color: #e9595b;
}
.ribbon-danger.ribbon-bottom .ribbon-inner:after {
  border-top-color: transparent;
  border-bottom-color: #e9595b;
}
.dotnav {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}
.dotnav > li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 16px;
  vertical-align: top;
  list-style: none;
}
.dotnav > li > a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}
.dotnav > li > a:focus {
  outline: none;
}
.dotnav > li.active > a,
.dotnav > li:hover > a,
.dotnav > li:focus > a {
  background-color: #ffffff;
}
.dotnav-scaleup > li > a {
  -webkit-transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-scaleup > li.active > a {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.dotnav-stroke > li > a {
  border: 2px solid transparent;
  -webkit-transition: border 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: border 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: border 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-stroke > li.active > a {
  background-color: transparent;
  border-color: #ffffff;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.dotnav-fillin > li > a {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fff inset;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
}
.dotnav-fillin > li:hover > a,
.dotnav-fillin > li:focus > a {
  background-color: transparent;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.6) inset;
}
.dotnav-fillin > li.active > a {
  box-shadow: 0 0 0 8px #fff inset;
}
.dotnav-dotstroke > li > a {
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5) inset;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
}
.dotnav-dotstroke > li.active > a {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 2px #fff inset;
}
.dotnav-fall > li:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  -webkit-transform: translate(0%, -200%);
  -ms-transform: translate(0%, -200%);
  -o-transform: translate(0%, -200%);
  transform: translate(0%, -200%);
  -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;
}
.dotnav-fall > li > a {
  -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, background-color 0.3s ease 0s;
}
.dotnav-fall > li.active:after {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  -o-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  -webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
}
.dotnav-fall > li.active > a {
  opacity: 0;
  -webkit-transform: translate(0, 200%);
  -ms-transform: translate(0, 200%);
  -o-transform: translate(0, 200%);
  transform: translate(0, 200%);
}
.color-selector {
  padding: 0;
  margin: 0;
  list-style: none;
}
.color-selector > li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 8px 8px 0;
  background-color: #62a8ea;
  border-radius: 100%;
}
.color-selector > li:hover {
  opacity: .8;
}
.color-selector > li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: inherit;
  height: inherit;
  content: "";
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: inherit;
}
.color-selector > li input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: inherit;
  height: inherit;
  cursor: pointer;
  border-radius: inherit;
  opacity: 0;
}
.color-selector > li input[type="radio"]:disabled {
  cursor: not-allowed;
}
.color-selector > li label {
  position: relative;
  font-family: "Web Icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}
.color-selector > li input[type="radio"]:checked + label:after {
  position: absolute;
  top: 0;
  left: 8px;
  display: inline-block;
  margin-top: -2px;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  content: "\f192";
}
.color-selector > li.color-selector-disabled {
  background-color: #ccd5db !important;
}
.color-selector > li.color-selector-disabled input[type="radio"]:disabled {
  cursor: not-allowed;
}
.width-50 {
  width: 50px;
}
.width-100 {
  width: 100px;
}
.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-250 {
  width: 250px;
}
.width-300 {
  width: 300px;
}
.width-350 {
  width: 350px;
}
.width-400 {
  width: 400px;
}
.width-450 {
  width: 450px;
}
.width-500 {
  width: 500px;
}
.width-full {
  width: 100% !important;
}
@media (max-width: 767px) {
  .width-xs-50 {
    width: 50px;
  }
  .width-xs-100 {
    width: 100px;
  }
  .width-xs-150 {
    width: 150px;
  }
  .width-xs-200 {
    width: 200px;
  }
  .width-xs-250 {
    width: 250px;
  }
  .width-xs-300 {
    width: 300px;
  }
  .width-xs-350 {
    width: 350px;
  }
  .width-xs-400 {
    width: 400px;
  }
  .width-xs-450 {
    width: 450px;
  }
  .width-xs-500 {
    width: 500px;
  }
  .width-xs-100pc {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .width-sm-50 {
    width: 50px;
  }
  .width-sm-100 {
    width: 100px;
  }
  .width-sm-150 {
    width: 150px;
  }
  .width-sm-200 {
    width: 200px;
  }
  .width-sm-250 {
    width: 250px;
  }
  .width-sm-300 {
    width: 300px;
  }
  .width-sm-350 {
    width: 350px;
  }
  .width-sm-400 {
    width: 400px;
  }
  .width-sm-450 {
    width: 450px;
  }
  .width-sm-500 {
    width: 500px;
  }
  .width-sm-100pc {
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .width-md-50 {
    width: 50px;
  }
  .width-md-100 {
    width: 100px;
  }
  .width-md-150 {
    width: 150px;
  }
  .width-md-200 {
    width: 200px;
  }
  .width-md-250 {
    width: 250px;
  }
  .width-md-300 {
    width: 300px;
  }
  .width-md-350 {
    width: 350px;
  }
  .width-md-400 {
    width: 400px;
  }
  .width-md-450 {
    width: 450px;
  }
  .width-md-500 {
    width: 500px;
  }
}
@media (min-width: 1200px) {
  .width-lg-50 {
    width: 50px;
  }
  .width-lg-100 {
    width: 100px;
  }
  .width-lg-150 {
    width: 150px;
  }
  .width-lg-200 {
    width: 200px;
  }
  .width-lg-250 {
    width: 250px;
  }
  .width-lg-300 {
    width: 300px;
  }
  .width-lg-350 {
    width: 350px;
  }
  .width-lg-400 {
    width: 400px;
  }
  .width-lg-450 {
    width: 450px;
  }
  .width-lg-500 {
    width: 500px;
  }
}
.height-50 {
  height: 50px;
}
.height-100 {
  height: 100px;
}
.height-120 {
  height: 120px;
}
.height-150 {
  height: 150px;
}
.height-200 {
  height: 200px;
}
.height-250 {
  height: 250px;
}
.height-300 {
  height: 300px;
}
.height-350 {
  height: 350px;
}
.height-400 {
  height: 400px;
}
.height-450 {
  height: 450px;
}
.height-500 {
  height: 500px;
}
.height-full {
  height: 100% !important;
}
@media (max-width: 767px) {
  .height-xs-50 {
    height: 50px;
  }
  .height-xs-100 {
    height: 100px;
  }
  .height-xs-120 {
    height: 120px;
  }
  .height-xs-150 {
    height: 150px;
  }
  .height-xs-200 {
    height: 200px;
  }
  .height-xs-250 {
    height: 250px;
  }
  .height-xs-300 {
    height: 300px;
  }
  .height-xs-350 {
    height: 350px;
  }
  .height-xs-400 {
    height: 400px;
  }
  .height-xs-450 {
    height: 450px;
  }
  .height-xs-500 {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .height-sm-50 {
    height: 50px;
  }
  .height-sm-100 {
    height: 100px;
  }
  .height-sm-120 {
    height: 120px;
  }
  .height-sm-150 {
    height: 150px;
  }
  .height-sm-200 {
    height: 200px;
  }
  .height-sm-250 {
    height: 250px;
  }
  .height-sm-300 {
    height: 300px;
  }
  .height-sm-350 {
    height: 350px;
  }
  .height-sm-400 {
    height: 400px;
  }
  .height-sm-450 {
    height: 450px;
  }
  .height-sm-500 {
    height: 500px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .height-md-50 {
    height: 50px;
  }
  .height-md-100 {
    height: 100px;
  }
  .height-md-120 {
    height: 120px;
  }
  .height-md-150 {
    height: 150px;
  }
  .height-md-200 {
    height: 200px;
  }
  .height-md-250 {
    height: 250px;
  }
  .height-md-300 {
    height: 300px;
  }
  .height-md-350 {
    height: 350px;
  }
  .height-md-400 {
    height: 400px;
  }
  .height-md-450 {
    height: 450px;
  }
  .height-md-500 {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .height-lg-50 {
    height: 50px;
  }
  .height-lg-100 {
    height: 100px;
  }
  .height-lg-120 {
    height: 120px;
  }
  .height-lg-150 {
    height: 150px;
  }
  .height-lg-200 {
    height: 200px;
  }
  .height-lg-250 {
    height: 250px;
  }
  .height-lg-300 {
    height: 300px;
  }
  .height-lg-350 {
    height: 350px;
  }
  .height-lg-400 {
    height: 400px;
  }
  .height-lg-450 {
    height: 450px;
  }
  .height-lg-500 {
    height: 500px;
  }
}
.margin-0 {
  margin: 0 !important;
}
.margin-3 {
  margin: 3px !important;
}
.margin-5 {
  margin: 5px !important;
}
.margin-10 {
  margin: 10px !important;
}
.margin-15 {
  margin: 15px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-25 {
  margin: 25px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-35 {
  margin: 35px !important;
}
.margin-40 {
  margin: 40px !important;
}
.margin-45 {
  margin: 45px !important;
}
.margin-50 {
  margin: 50px !important;
}
.margin-60 {
  margin: 60px !important;
}
.margin-70 {
  margin: 70px !important;
}
.margin-80 {
  margin: 80px !important;
}
.margin-vertical-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin-vertical-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.margin-vertical-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.margin-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.margin-vertical-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.margin-vertical-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.margin-vertical-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.margin-vertical-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.margin-vertical-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.margin-vertical-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.margin-vertical-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.margin-vertical-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.margin-vertical-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.margin-vertical-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.margin-vertical-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.margin-horizontal-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.margin-horizontal-3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}
.margin-horizontal-5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.margin-horizontal-10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.margin-horizontal-15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}
.margin-horizontal-20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.margin-horizontal-25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}
.margin-horizontal-30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}
.margin-horizontal-35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}
.margin-horizontal-40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}
.margin-horizontal-45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}
.margin-horizontal-50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}
.margin-horizontal-60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}
.margin-horizontal-70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}
.margin-horizontal-80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.margin-bottom-3 {
  margin-bottom: 3px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-3 {
  margin-left: 3px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
@media (max-width: 767px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}
@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}
@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}
.padding-0 {
  padding: 0 !important;
}
.padding-3 {
  padding: 3px !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-45 {
  padding: 45px !important;
}
.padding-50 {
  padding: 50px !important;
}
.padding-60 {
  padding: 60px !important;
}
.padding-70 {
  padding: 70px !important;
}
.padding-80 {
  padding: 80px !important;
}
.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-vertical-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.padding-vertical-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.padding-vertical-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.padding-vertical-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.padding-vertical-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.padding-vertical-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.padding-vertical-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.padding-vertical-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.padding-vertical-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.padding-vertical-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.padding-vertical-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.padding-vertical-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.padding-vertical-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.padding-vertical-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.padding-horizontal-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.padding-horizontal-3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}
.padding-horizontal-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.padding-horizontal-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.padding-horizontal-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.padding-horizontal-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.padding-horizontal-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.padding-horizontal-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.padding-horizontal-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.padding-horizontal-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.padding-horizontal-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}
.padding-horizontal-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}
.padding-horizontal-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.padding-horizontal-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}
.padding-horizontal-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-3 {
  padding-top: 3px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-3 {
  padding-bottom: 3px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-3 {
  padding-left: 3px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-3 {
  padding-right: 3px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}
@media (max-width: 767px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}
@media (min-width: 768px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}
@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .padding-lg-0 {
    padding: 0 !important;
  }
}
.example-wrap {
  margin-bottom: 80px;
}
.example-wrap .example-wrap {
  margin-bottom: 0;
}
.example {
  margin-top: 20px;
  margin-bottom: 20px;
}
.example:before,
.example:after {
  content: " ";
  display: table;
}
.example:after {
  clear: both;
}
.example-title {
  text-transform: uppercase;
}
.example-title,
h4.example-title {
  font-size: 14px;
}
.panel-body > .example-wrap:last-child {
  margin-bottom: 0;
}
.panel-body > .row:last-child > [class*="col-"]:last-child .example-wrap:last-child {
  margin-bottom: 0;
}
.example-well {
  position: relative;
  margin-bottom: 30px;
  background-color: #f3f7f9;
}
.example-well .center {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.example-dropdown .dropdown:before,
.example-dropdown .dropup:before,
.example-dropdown .dropdown:after,
.example-dropdown .dropup:after {
  content: " ";
  display: table;
}
.example-dropdown .dropdown:after,
.example-dropdown .dropup:after {
  clear: both;
}
.example-dropdown .dropdown > .dropdown-toggle,
.example-dropdown .dropup > .dropdown-toggle {
  float: left;
}
.example-dropdown .dropdown > .dropdown-menu,
.example-dropdown .dropup > .dropdown-menu {
  position: static;
  display: block;
  clear: left;
}
.example-dropdown .dropdown > .dropdown-menu-right,
.example-dropdown .dropup > .dropdown-menu-right {
  float: right;
  clear: right;
}
.example-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.example-tooltip .tooltip {
  position: relative;
  margin-right: 25px;
  opacity: 1;
}
.example-grid .example-col,
.example-blocks .example-col {
  min-height: 0;
  padding: 10px 15px 12px;
  background-color: #f3f7f9;
  border-radius: 0;
}
.example-grid .example-col {
  margin-bottom: 20px;
}
.example-grid .example-col .example-col {
  margin-top: 20px;
  margin-bottom: 0;
  background-color: #e2ecf1;
}
.example-popover {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.example-popover .popover {
  position: relative;
  display: block;
  margin-right: 25px;
}
.example-buttons .btn,
.example-buttons .btn-group,
.example-buttons .btn-group-vertical {
  margin-right: 15px;
  margin-bottom: 20px;
}
.example-buttons .btn-group-vertical .btn,
.example-buttons .btn-group .btn {
  margin-right: 0;
  margin-bottom: 0;
}
.example-box {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 45px 15px 15px;
  border: 1px solid #e4eaec;
}
.example-box:after {
  position: absolute;
  top: 15px;
  left: 15px;
  content: "Example";
  color: #959595;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.example-avatars .avatar {
  margin-right: 20px;
  margin-bottom: 20px;
}
.example-avatars .avatar:last-child {
  margin-right: 20px;
}
.example-typography {
  position: relative;
  padding-left: 25%;
}
.example-typography .heading-note,
.example-typography .text-note {
  position: absolute;
  bottom: 2px;
  left: 0;
  display: block;
  width: 260px;
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  color: #AAB2BD;
}
.example-typography .text-note {
  top: 10px;
  bottom: auto;
}
.example-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .example-responsive {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
/*IMAGE EDITOR */
.cropper-preview {
  overflow: hidden;
}
.img-preview {
  float: left;
  margin: 0 10px 10px 0;
  overflow: hidden;
}
.img-preview > img {
  max-width: 100%;
}
.preview-lg {
  width: 263px;
  height: 148px;
}
.preview-md {
  width: 139px;
  height: 78px;
}
.preview-sm {
  width: 69px;
  height: 39px;
}
.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0;
}
.cropper,
.cropper-toolbar {
  margin-bottom: 30px;
}
@media (min-width: 1200px) {
  .cropper {
    max-height: 600px;
  }
}
@media (min-width: 769px) {
  .cropper {
    max-height: 400px;
  }
}
@media (max-width: 768px) {
  .cropper {
    max-height: 300px;
  }
}
@media (max-width: 480px) {
  .cropper {
    max-height: 246px;
  }
}
@media (max-width: 586px) {
  .btn-group .btn {
    padding: 6px 8px;
  }
  .cropper {
    max-height: 246px;
  }
}
.grid-stack-item-content {
  font-size: 20px;
  color: #526069;
  text-align: center;
  background: white;
}
.grid-stack-item-content .fa {
  display: block;
  margin: 20px 0 10px;
  font-size: 64px;
}
.gridster ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.gridster ul li {
  list-style: none;
}
.gridster-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.widget {
  text-align: left;
  font-size: 14px;
}
.widget .table {
  font-size: 14px;
}
.widget-side {
  display: none;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.widget-side i {
  font-size: 30px;
  display: block;
}
.tp-widget {
  position: relative;
}
.tp-widget .widget-admin {
  display: none;
}
.tp-widget .widget-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 100;
  background: rgba(255, 255, 255, 0.6);
}
.widget-sortable .widget-front {
  position: relative;
}
.widget-sortable .widget-front .widget-helpers {
  display: none;
}
.widget-sortable .widget-front:hover .widget-helpers {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: -32px;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px 10px;
}
.widget-sortable .widget-front:hover .widget-helpers .btn {
  margin: 0 10px;
}
.widget-avail .widget-side {
  display: block;
}
.widget-avail .widget-front {
  display: none;
}
#widget-wrapper {
  padding: 30px;
  min-height: 300px;
  width: 100%;
}
.yui3-cssreset html {
  color: #000;
  background: #FFF;
}
.yui3-cssreset body,
.yui3-cssreset div,
.yui3-cssreset dl,
.yui3-cssreset dt,
.yui3-cssreset dd,
.yui3-cssreset ul,
.yui3-cssreset ol,
.yui3-cssreset li,
.yui3-cssreset h1,
.yui3-cssreset h2,
.yui3-cssreset h3,
.yui3-cssreset h4,
.yui3-cssreset h5,
.yui3-cssreset h6,
.yui3-cssreset pre,
.yui3-cssreset code,
.yui3-cssreset form,
.yui3-cssreset fieldset,
.yui3-cssreset legend,
.yui3-cssreset input,
.yui3-cssreset textarea,
.yui3-cssreset p,
.yui3-cssreset blockquote,
.yui3-cssreset th,
.yui3-cssreset td {
  margin: 0;
  padding: 0;
}
.yui3-cssreset table {
  border-collapse: collapse;
  border-spacing: 0;
}
.yui3-cssreset fieldset,
.yui3-cssreset img {
  border: 0;
}
.yui3-cssreset address,
.yui3-cssreset caption,
.yui3-cssreset cite,
.yui3-cssreset code,
.yui3-cssreset dfn,
.yui3-cssreset em,
.yui3-cssreset strong,
.yui3-cssreset th,
.yui3-cssreset var {
  font-style: normal;
  font-weight: normal;
}
.yui3-cssreset ol,
.yui3-cssreset ul {
  list-style: none;
}
.yui3-cssreset caption,
.yui3-cssreset th {
  text-align: left;
}
.yui3-cssreset h1,
.yui3-cssreset h2,
.yui3-cssreset h3,
.yui3-cssreset h4,
.yui3-cssreset h5,
.yui3-cssreset h6 {
  font-size: 100%;
  font-weight: normal;
}
.yui3-cssreset q:before,
.yui3-cssreset q:after {
  content: '';
}
.yui3-cssreset abbr,
.yui3-cssreset acronym {
  border: 0;
  font-variant: normal;
}
.yui3-cssreset sup {
  vertical-align: text-top;
}
.yui3-cssreset sub {
  vertical-align: text-bottom;
}
.yui3-cssreset input,
.yui3-cssreset textarea,
.yui3-cssreset select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.yui3-cssreset input,
.yui3-cssreset textarea,
.yui3-cssreset select {
  *font-size: 100%;
}
.yui3-cssreset legend {
  color: #000;
}
#yui3-css-stamp.cssreset-context {
  display: none;
}
.yui3-cssbase h1 {
  font-size: 138.5%;
}
.yui3-cssbase h2 {
  font-size: 123.1%;
}
.yui3-cssbase h3 {
  font-size: 108%;
}
.yui3-cssbase h1,
.yui3-cssbase h2,
.yui3-cssbase h3 {
  margin: 1em 0;
}
.yui3-cssbase h1,
.yui3-cssbase h2,
.yui3-cssbase h3,
.yui3-cssbase h4,
.yui3-cssbase h5,
.yui3-cssbase h6,
.yui3-cssbase strong {
  font-weight: bold;
}
.yui3-cssbase abbr,
.yui3-cssbase acronym {
  border-bottom: 1px dotted #000;
  cursor: help;
}
.yui3-cssbase em {
  font-style: italic;
}
.yui3-cssbase blockquote,
.yui3-cssbase ul,
.yui3-cssbase ol,
.yui3-cssbase dl {
  margin: 1em;
}
.yui3-cssbase ol,
.yui3-cssbase ul,
.yui3-cssbase dl {
  margin-left: 2em;
}
.yui3-cssbase ol {
  list-style: decimal outside;
}
.yui3-cssbase ul {
  list-style: disc outside;
}
.yui3-cssbase dl dd {
  margin-left: 1em;
}
.yui3-cssbase th,
.yui3-cssbase td {
  border: 1px solid #000;
  padding: 0.5em;
}
.yui3-cssbase th {
  font-weight: bold;
  text-align: center;
}
.yui3-cssbase caption {
  margin-bottom: .5em;
  text-align: center;
}
.yui3-cssbase p,
.yui3-cssbase fieldset,
.yui3-cssbase table,
.yui3-cssbase pre {
  margin-bottom: 1em;
}
.yui3-cssbase input[type=text],
.yui3-cssbase input[type=password],
.yui3-cssbase textarea {
  width: 12.25em;
  *width: 11.9em;
}
#yui3-css-stamp.cssbase-context {
  display: none;
}
.yui3-cssreset h1 {
  color: unset;
}
.yui3-cssreset {
  all: initial;
}
.yui3-cssreset * {
  all: unset;
}
.page-aside.aside-fixed {
  position: fixed;
  top: 66px;
  left: 260px;
  bottom: 0;
  max-height: 100%;
  max-width: 100%;
}
.page-aside.aside-fixed .page-aside-inner {
  overflow: hidden;
  height: 100%;
}
@media (max-width: 1199px) {
  .page-aside.aside-fixed {
    left: 90px;
  }
}
@media (max-width: 767px) {
  .page-aside.aside-fixed {
    left: 0;
  }
}
.ceditor .mce-panel {
  background: #222733 !important;
}
.ceditor .mce-panel .mce-btn {
  background: #222733 !important;
}
.ceditor .mce-panel .mce-btn button {
  color: #fff !important;
}
.ceditor .mce-panel .mce-btn button i {
  color: #fff !important;
}
.ceditor .mce-panel .mce-btn button i.mce-caret {
  border-top-color: #fff !important;
}
.app-message .page {
  height: calc(100% -  44px);
}
.app-message .page-main {
  height: 100%;
}
.app-message .page-aside .form-control {
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.06);
  padding: 10px 60px 10px 30px;
  height: 56px;
}
.app-message .page-aside .input-search-btn {
  padding-right: 30px;
  border-radius: 0;
}
.app-message .page-aside-inner {
  height: 100%;
}
.app-message-list {
  height: calc(100% -  56px);
}
.app-message-list .list-group .list-group-item {
  border-bottom: 0;
  border-radius: 0;
  padding: 16px 30px;
  white-space: nowrap;
  z-index: 0;
}
.app-message-list .list-group .list-group-item:hover,
.app-message-list .list-group .list-group-item.active {
  background-color: #e8f1f8;
}
.app-message-list .list-group .list-group-item.active:after {
  width: 1px;
  position: absolute;
  content: ' ';
  height: 100%;
  right: 0;
  top: 0;
  background: #62a8ea;
}
.app-message-list .list-group .list-group-item .media-time {
  color: #a3afb7;
}
.app-message-chats {
  padding: 20px 30px;
  overflow-y: scroll;
  text-align: center;
  height: calc(100% -  96px);
  background-color: #ffffff;
}
.app-message-chats > .btn {
  color: #89bceb;
}
.app-message-chats > .btn:hover {
  color: #76838f;
}
.app-message-chats .chats {
  padding: 0;
}
.app-message-chats .chats .chat-avatar .avatar {
  width: 60px;
  margin-top: -10px;
}
.app-message-chats .chats .time {
  color: #a3afb7;
  font-size: 12px;
  text-align: center;
  margin: 40px 0;
}
.app-message-input {
  position: relative;
  padding: 30px;
  padding-right: 110px;
  background-color: #f3f7f9;
}
.app-message-input .message-input {
  position: relative;
}
.app-message-input .message-input textarea {
  height: 36px;
  resize: none;
  max-height: 100px;
  padding-right: 110px;
}
.app-message-input .message-input-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
.app-message-input .message-input-actions input {
  display: none;
}
.app-message-input .message-input-btn {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
@media (max-width: 1199px) {
  .app-message .page-aside .list-group-item {
    padding: 14px 16px;
    font-size: 12px;
  }
  .app-message .page-aside .list-group-item h4 {
    font-size: 12px;
  }
  .app-message .page-aside .list-group-item .avatar {
    width: 34px;
  }
  .app-message .page-aside .list-group-item .badge {
    padding: 1px 4px;
  }
}
@media (max-width: 480px) {
  .app-message .page {
    height: calc(100% -  0px);
  }
}
.lang-switch-nav {
  float: right;
  padding-left: 20px;
}
.lang-switch-nav a {
  opacity: .4;
}
.lang-switch-nav a.active {
  opacity: 1;
}
.tag {
  display: inline-block;
  margin-right: 5px;
  background: #e4eaec;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 3px;
}
.tag.category1 {
  background: #57c7d4;
  color: #fff;
}
.tag.category2 {
  background: #926dde;
  color: #fff;
}
.tag-agent {
  display: inline-block;
  margin-right: 10px;
  font-size: 12px;
}
.selectize-input {
  border: 1px solid #e4eaec;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.selectize-control .item {
  background: #e4eaec !important;
  padding: 2px 5px;
  border-radius: 3px;
}
.selectize-control.category1 .item {
  background: #57c7d4 !important;
  color: #fff !important;
}
.selectize-control.category2 .item {
  background: #926dde !important;
  color: #fff !important;
}
a.avatar {
  text-decoration: none;
}
.avatar-initials {
  display: block;
  height: 40px;
  background: #ccd5db;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  text-decoration: none;
  color: #fff;
}
.panel.clean {
  background: none;
  border: none;
  box-shadow: none;
}
.site-menubar {
  background: #222733;
  padding-top: 15px;
  width: 260px;
}
.site-menubar-unfold .site-menu > .site-menu-item > a .site-menu-title {
  font-size: 15px;
}
.site-menubar-unfold .site-menu > .site-menu-item > a {
  line-height: 35px;
}
.table .bootstrap-select .btn {
  background: none;
  border: none;
}
.table .bootstrap-select .btn .caret {
  display: none;
}
.table .bootstrap-select {
  width: auto !important;
}
.page-dark.layout-full:after {
  background-color: rgba(38, 50, 56, 0.8);
}
.pearls .badge {
  position: absolute;
  top: -10px;
  right: -10px;
}
.pearls.row {
  margin: 0 0 15px 0;
}
.pearl-title {
  font-size: 14px;
}
.sortablefield {
  cursor: pointer;
}
.sortablefield.active {
  font-weight: bold;
}
/*
Similar to no-borders option
but because of Laravel pagination
we hack'it directly from here
*/
.pagination li a,
.pagination li span {
  border: none;
}
.panel.is-fullscreen {
  overflow: auto;
}
#toast-container > div {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel .panel-heading {
  min-height: 50px;
}
.paging {
  min-width: 50px;
}
.paging li a {
  text-align: right;
}
.paging li a.active {
  color: #57c7d4;
}
.panel.is-fullscreen .panel-heading {
  position: fixed;
  width: 100%;
  height: 50px;
  opacity: .7;
  background: #fff;
  z-index: 999;
}
.panel.is-fullscreen .panel-heading:hover {
  opacity: 1;
}
.panel.is-fullscreen .panel-body {
  padding-top: 60px;
}
.panel.is-fullscreen ul.pagination {
  position: fixed;
  bottom: 10px;
  left: 100px;
}
.modal-backdrop.in {
  opacity: .3;
}
.bootbox.modal {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-box;
  display: flex;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}
.pearls-done .pearl:before,
.pearls-done .pearl:after {
  background: none;
}
.pearls-done .pearl:hover {
  text-decoration: none;
}
.pearls-done .pearl:hover .pearl-title {
  color: #62a8ea;
}
.help-block {
  display: block;
  float: left;
  height: 0;
  font-size: 85%;
}
.dropzone {
  border: 2px dotted rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  text-align: center;
}
.dropzone .dz-message {
  font-size: 16px;
  opacity: 0.3;
  text-transform: uppercase;
  margin-top: 40px;
  display: block;
  text-align: center;
}
.site-menu > .site-menu-item.active {
  background: rgba(98, 168, 234, 0.2);
}
.table-header-pagination {
  margin-top: -60px;
}
.timeline-normal .timeline-content {
  text-align: right;
}
.rotate90 {
  -ms-transform: rotate(90deg) !important;
  /* IE 9 */
  -webkit-transform: rotate(90deg) !important;
  /* Safari */
  transform: rotate(90deg) !important;
}
.h4-title {
  text-transform: uppercase;
  font-size: 14px;
}
/*SLIDE PANEL */
.slidePanel-header {
  padding: 30px 30px;
}
/*CALENDAR */
.fc-event {
  border: none !important;
}
/*SELECT 2 */
.select2-container {
  margin-bottom: 20px;
}
